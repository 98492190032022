import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  Text,
  VStack,
  useTheme,
} from "@chakra-ui/react";
import { RecordingStepper } from "../../../components/atoms/RecordingStepper";
import {
  IoChevronBack,
  IoChevronForward,
  IoPlaySkipForwardCircleOutline,
} from "react-icons/io5";
import { PhraseBarSoundVisualizer } from "../../../components/molecules/PhraseBarSoundVisualizer";
import { PhraseCircleSoundVisualizer } from "../../../components/molecules/PhraseCircleSoundVisualizer";
import { PhraseWaveSoundVisualizer } from "../../../components/molecules/PhraseWaveSoundVisualizer";
import { useAtom } from "jotai";
import {
  audioRecorder,
  detectVolumeAtom,
  questionnaireAtom,
  RawPhraseInfo,
  phrasesFromApiAtom,
  minimumUtterancesFromApiAtom,
  CompanySettingDataAtom,
  recTypeAtom,
  phraseVisualizerTypeAtom,
  detectedVolumeByPhraseVisualizerAtom,
} from "../../../store";
import { Navigate } from "react-router-dom";
import { Bars, RotatingLines } from "react-loader-spinner";
import { useToastMessage } from "../../../hooks/useToastMessage";
import { Layout } from "../../../components/atoms/Layout";
import { useAssertEngineTypeFromPathParam } from "../../../utils/selectAnalysisEngine";
import { DEBUG_AUDIO_PLAYER, SKIP_QUESTIONNAIRES } from "../../../environments";
import {
  AnalysisState,
  RecordedData,
  SoundVisualizerRef,
  CheckVolumeResult,
  RecordingState,
  InitializingState,
} from "../../../types";
import { useCheckVolume } from "../../../hooks/useCheckVolume";
import { useAnalyzeVoice } from "../../../hooks/useAnalyzeVoice";
import { DebugAudioPlayer } from "../../../components/atoms/DebugAudioPlayer";
import { ErrorDialog } from "../../../components/molecules/ErrorDialog";
import { RecordingButton } from "../../../components/molecules/RecordingButton";
import { useModifiedTranslation } from "../../../hooks/useModifiedTranslation";
import {
  mimosysVolumeToVolume,
  volumeToMimosysVolume,
} from "../../../utils/calcVolume";

const DEFAULT_MAX_SEC_OF_UTTERANCE = 10;
const DEFAULT_RECORDING_REQUIREMENT = false;
const COMPLETED_PHRASE: RawPhraseInfo = {
  titleKey: "PJ.OralFunctionAnalysis.Completed.Title",
  phraseKey: "PJ.OralFunctionAnalysis.Completed.Phrase",
} as const;

/*
type RawPhraseInfo = {
  titleKey: string;
  phraseKey: string;
  helperTextKey?: string;
  co1VoiceType?: string;
  limitSeconds?: number;
  dysphagiaVoiceTypes?: string;
  isRecordingRequired?: boolean;
};
 */

type PhraseData = {
  phraseText: PhraseText;
  limitSeconds: number;
  co1VoiceType?: string;
  dysphagiaVoiceTypes?: string;
  isRecordingRequired: boolean;
};

type PhraseText = { title: string; phrase: string; helperText?: string };

function StepperCommonButton(props: ButtonProps): ReactElement {
  return (
    <Button
      fontSize="14px"
      minHeight="30px"
      variant="btn_secondary"
      size="sm"
      {...props}
    />
  );
}

function Stepper(
  props: Readonly<{
    recordingState: RecordingState;
    currentPhraseIndex: number;
    allPhraseSize: number;
    minRecordedPhrase: number;
    allRecordedData: RecordedData[];
    onClickBack?: () => void;
    onClickForward?: () => void;
  }>
): ReactElement {
  const t = useModifiedTranslation();
  return (
    <Box mb={2}>
      <Flex justifyContent="space-between" alignItems="center" mb={1}>
        <StepperCommonButton
          disabled={props.recordingState === "RECORDING" || !props.onClickBack}
          onClick={props.onClickBack}
        >
          <Flex>
            <Icon boxSize={4} as={IoChevronBack} />
            <Text mr={1}>{t("Recording.previous")}</Text>
          </Flex>
        </StepperCommonButton>
        <StepperCommonButton
          disabled={
            props.recordingState === "RECORDING" || !props.onClickForward
          }
          onClick={props.onClickForward}
        >
          <Flex>
            <Text ml={1}>{t("Recording.next")}</Text>
            <Icon boxSize={4} as={IoChevronForward} />
          </Flex>
        </StepperCommonButton>
      </Flex>
      <RecordingStepper
        maxProgress={props.allPhraseSize}
        progress={props.currentPhraseIndex}
        allRecordedData={props.allRecordedData}
      />
    </Box>
  );
}

function getCurrentPhraseInfo(
  currentPhraseIndex: number,
  phraseList: RawPhraseInfo[],
  t: (key: string) => string
): PhraseData {
  if (currentPhraseIndex >= phraseList.length) {
    return {
      phraseText: {
        title: t(COMPLETED_PHRASE.titleKey),
        phrase: t(COMPLETED_PHRASE.phraseKey),
      },
      limitSeconds: DEFAULT_MAX_SEC_OF_UTTERANCE,
      isRecordingRequired: DEFAULT_RECORDING_REQUIREMENT,
    };
  }

  const phraseInfo = phraseList[currentPhraseIndex];
  const limitSeconds =
    phraseInfo.limitSeconds !== undefined
      ? phraseInfo.limitSeconds
      : DEFAULT_MAX_SEC_OF_UTTERANCE;
  const isRecordingRequired =
    phraseInfo.isRecordingRequired !== undefined
      ? phraseInfo.isRecordingRequired
      : DEFAULT_RECORDING_REQUIREMENT;
  return {
    phraseText: {
      title: t(phraseInfo.titleKey),
      phrase: t(phraseInfo.phraseKey),
      helperText: phraseInfo.helperTextKey
        ? t(phraseInfo.helperTextKey)
        : undefined,
    },
    limitSeconds,
    co1VoiceType: phraseInfo.co1VoiceType,
    dysphagiaVoiceTypes: phraseInfo.dysphagiaVoiceTypes,
    isRecordingRequired,
  };
}

type StopRecordingFunc = () => Promise<RecordedData | null>;

const phraseVisualizerMap = {
  Circle: PhraseCircleSoundVisualizer,
  Bar: PhraseBarSoundVisualizer,
  Wave: PhraseWaveSoundVisualizer,
};

function Recording(): ReactElement {
  const t = useModifiedTranslation();
  const toastMessage = useToastMessage();
  const engineType = useAssertEngineTypeFromPathParam();
  const analyzeVoice = useAnalyzeVoice(engineType);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [detectVolumeForMimosys] = useAtom(detectVolumeAtom);
  const [detectVolume] = useState(
    mimosysVolumeToVolume(detectVolumeForMimosys)
  );
  const [questionnaire] = useAtom(questionnaireAtom);
  const [initializingState, setInitializingState] =
    useState<InitializingState>("UNINITIALIZED");
  const [stopRecording, setStopRecording] = useState<
    null | "PREPARING" | "STOPPING" | StopRecordingFunc
  >(null);
  const visualizerRef = useRef<SoundVisualizerRef>(null);
  const [allRecordedData] = useState<RecordedData[]>([]);
  const [analysisStatus, setAnalysisStatus] = useState<AnalysisState>("IDLE");
  const checkVolume = useCheckVolume();
  // console.log("engineType:" + JSON.stringify(engineType,null,'\t'));

  // const minRecordedPhrase = allPhrases[engineType].minRecordedPhrase;
  // minimum_utterances（元気圧の計算に必要な最少発話数）のグローバル状態管理用
  const [minimumUtterancesFromApi] = useAtom(minimumUtterancesFromApiAtom);
  const [errorDialogMessage, setErrorDialogMessage] = useState("");

  const minRecordedPhrase = minimumUtterancesFromApi;

  // アプリ設定一括取得（/analysis/app/settings）のphrases（読み上げ文配列）のグローバル状態を取得
  const [phrasesFromApi] = useAtom(phrasesFromApiAtom);

  const phraseList = phrasesFromApi;

  const requestIdRef = useRef<null | string>(null);

  const {
    phraseText,
    limitSeconds,
    co1VoiceType,
    dysphagiaVoiceTypes,
    isRecordingRequired,
  } = useMemo(
    () => getCurrentPhraseInfo(currentPhraseIndex, phraseList, t),
    [currentPhraseIndex, phraseList, t]
  );

  const [phraseVisualizerType] = useAtom(phraseVisualizerTypeAtom);
  const PhraseVisualizer = phraseVisualizerMap[phraseVisualizerType];

  const [, setIsDetectedVolume] = useAtom(detectedVolumeByPhraseVisualizerAtom);

  const startRecording = useCallback(async () => {
    if (!audioRecorder) return;
    setStopRecording("PREPARING");
    setIsDetectedVolume(false);
    const updatingCallback = (
      averagePower: number,
      maxPower: number,
      detectVolumeFlag: boolean
    ): void => {
      if (visualizerRef.current) {
        visualizerRef.current.draw(
          averagePower,
          1 === maxPower,
          detectVolumeFlag
        );
      }
    };
    await audioRecorder.startRecording(
      updatingCallback,
      false,
      volumeToMimosysVolume(detectVolume),
      undefined,
      true
    );
    const stop: StopRecordingFunc = async () => {
      setStopRecording("STOPPING");
      const samplingRate = audioRecorder.samplingRate;
      const data = await audioRecorder.stopRecording({});
      if (visualizerRef.current) {
        visualizerRef.current.draw(0, false, false);
      }
      const phrase = phraseText.phrase;
      setStopRecording(null);
      if (data === null || samplingRate === undefined) return null;
      return {
        data,
        samplingRate,
        phrase,
        isSkipped: false,
        co1VoiceType,
        dysphagiaVoiceTypes,
      };
    };
    setStopRecording(() => stop);
  }, [
    co1VoiceType,
    phraseText.phrase,
    dysphagiaVoiceTypes,
    detectVolume,
    setIsDetectedVolume,
  ]);

  const skipRecording = useCallback(() => {
    const samplingRate = 0;
    const data = new Float32Array([]);
    if (visualizerRef.current) {
      visualizerRef.current.draw(0, false, false);
    }
    const phrase = phraseText.phrase;
    const isSkipped = true;
    return {
      data,
      samplingRate,
      phrase,
      isSkipped,
      co1VoiceType,
      dysphagiaVoiceTypes,
    };
  }, [co1VoiceType, phraseText.phrase, dysphagiaVoiceTypes]);

  const stepToNextPhrase = useCallback(
    async (recordedData: RecordedData) => {
      if (!recordedData.isSkipped) {
        const volumeCheckResult: CheckVolumeResult = await checkVolume(
          recordedData.data,
          recordedData.samplingRate,
          detectVolume
        );
        if (volumeCheckResult === "Error.allDataIsSilent") {
          setErrorDialogMessage(volumeCheckResult);
        }
        if (volumeCheckResult !== "OK") {
          console.log("return null");
          return null;
        }
      }
      allRecordedData[currentPhraseIndex] = recordedData;
      if (currentPhraseIndex < phraseList.length) {
        setCurrentPhraseIndex(currentPhraseIndex + 1);
      }
    },
    [
      allRecordedData,
      checkVolume,
      currentPhraseIndex,
      detectVolume,
      phraseList.length,
    ]
  );

  const stepBack = useMemo(() => {
    return currentPhraseIndex <= 0
      ? undefined
      : () => setCurrentPhraseIndex(currentPhraseIndex - 1);
  }, [currentPhraseIndex]);

  const stepForward = useMemo(() => {
    return currentPhraseIndex >= allRecordedData.length
      ? undefined
      : () => setCurrentPhraseIndex(currentPhraseIndex + 1);
  }, [allRecordedData.length, currentPhraseIndex]);

  // 初期化処理
  useEffect(() => {
    if (phraseList.length < minimumUtterancesFromApi) {
      setErrorDialogMessage("Error.insufficientPhrases");
    }
    if (initializingState === "UNINITIALIZED") {
      setInitializingState("INITIALIZING");
      audioRecorder
        .init()
        .then(() => setInitializingState("INITIALIZED"))
        .catch((e) => {
          console.warn(e);
          const messageKey =
            e.name === "NotAllowedError"
              ? "Error.notAllowToUseMicrophone"
              : "Error.toUseMicrophone";
          setErrorDialogMessage(messageKey);
        });
    }
  }, [
    initializingState,
    t,
    toastMessage,
    phraseList,
    minimumUtterancesFromApi,
  ]);

  // クリーンアップ
  useEffect(() => {
    return () => {
      requestIdRef.current = null;
      setInitializingState("DESTROYING");
      audioRecorder.destroy().then();
    };
  }, []);

  // 録音タイムアウト設定
  useEffect(() => {
    let timeoutId: number | undefined = undefined;
    if (typeof stopRecording === "function") {
      timeoutId = window.setTimeout(async () => {
        const res = await stopRecording();
        if (res !== null) {
          return stepToNextPhrase(res);
        }
      }, limitSeconds * 1000);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [limitSeconds, stepToNextPhrase, stopRecording]);

  let recordingState: RecordingState;
  if (initializingState !== "INITIALIZED") {
    recordingState = "INITIALIZING";
  } else if (stopRecording !== null) {
    if (stopRecording === "PREPARING") {
      recordingState = "PREPARING";
    } else if (stopRecording === "STOPPING") {
      recordingState = "STOPPING";
    } else {
      recordingState = "RECORDING";
    }
  } else if (currentPhraseIndex >= phraseList.length) {
    recordingState = "COMPLETED";
  } else {
    recordingState = "IDLE";
  }
  const totalRecordedData = allRecordedData.reduce(
    (total, data) => (data.isSkipped ? total : total + 1),
    0
  );
  const theme = useTheme();
  const statusIndicatorColor = theme.colors.primary["theme_lv1"];
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  const [isRecorded, setIsRecorded] = useState(false);
  useEffect(() => {
    if (
      allRecordedData.length > currentPhraseIndex &&
      allRecordedData[currentPhraseIndex].isSkipped === false
    ) {
      setIsRecorded(true);
    } else {
      setIsRecorded(false);
    }
  }, [allRecordedData, currentPhraseIndex]);

  return (
    <>
      {/* NGダイアログ */}
      <ErrorDialog messageKey={errorDialogMessage} />
      <Layout
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Layout.Title showBackToHomeButton fontSize="24px">
          {phraseText.title}
        </Layout.Title>
        <Stepper
          recordingState={recordingState}
          allPhraseSize={phraseList.length}
          currentPhraseIndex={currentPhraseIndex}
          minRecordedPhrase={minRecordedPhrase}
          allRecordedData={allRecordedData}
          onClickBack={stepBack}
          onClickForward={stepForward}
        />
        <StepperCommonButton
          disabled={
            isRecordingRequired ||
            recordingState === "RECORDING" ||
            recordingState === "COMPLETED"
          }
          margin="0 0 0 auto"
          onClick={() => {
            const result = skipRecording();
            if (result) {
              return stepToNextPhrase(result);
            } else {
              return setErrorDialogMessage("Error.allDataIsSilent");
            }
          }}
        >
          <Flex>
            <Text ml={1}>{t("Recording.skip")}</Text>
            <Icon boxSize={4} as={IoPlaySkipForwardCircleOutline} />
          </Flex>
        </StepperCommonButton>
        <PhraseVisualizer
          ref={visualizerRef}
          currentPhrase={phraseText}
          isRecording={recordingState === "RECORDING"}
        />
        <RecordingButton
          recordingState={recordingState}
          isRerecord={isRecorded}
          onClick={
            typeof stopRecording === "function"
              ? () => {
                  stopRecording().then((result) => {
                    if (result) {
                      return stepToNextPhrase(result);
                    } else {
                      setErrorDialogMessage("Error.allDataIsSilent");
                    }
                  });
                }
              : startRecording
          }
        />
        <Button
          variant="btn_primary"
          width="full"
          mt={2}
          isLoading={analysisStatus !== "IDLE"}
          disabled={
            totalRecordedData < minRecordedPhrase ||
            recordingState === "RECORDING"
          }
          onClick={() =>
            analyzeVoice(
              allRecordedData,
              questionnaire,
              requestIdRef,
              setAnalysisStatus
            )
          }
          fontSize="xl"
        >
          {t("Recording.startAnalysis")}
        </Button>

        {(import.meta.env.DEV || DEBUG_AUDIO_PLAYER) && (
          <DebugAudioPlayer
            title={audioRecorder.audioWrapperType}
            allRecordedData={allRecordedData}
          />
        )}

        {/* 解析中画面 */}
        <Modal
          size="full"
          onClose={() => undefined}
          isOpen={analysisStatus !== "IDLE"}
          isCentered
        >
          <ModalContent backgroundColor="primary.bg_lv1">
            <ModalBody display="flex" alignItems="center">
              {analysisStatus === "UPLOADING" ? (
                <VStack margin="auto">
                  <RotatingLines
                    width="100"
                    strokeColor={statusIndicatorColor}
                  />
                  <Text
                    color="primary.theme_lv1"
                    fontSize="2xl"
                    textAlign="center"
                  >
                    Uploading
                  </Text>
                </VStack>
              ) : (
                <VStack margin="auto">
                  <Bars width="100" color={statusIndicatorColor} />
                  <Text
                    color="primary.theme_lv1"
                    fontSize="2xl"
                    textAlign="center"
                  >
                    Analyzing
                  </Text>
                </VStack>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Layout>
    </>
  );
}

export function ProtectedRecording(): ReactElement {
  const [questionnaire] = useAtom(questionnaireAtom);
  // mimosys_company_settings（契約者のMIMOSYS設定）のグローバル状態管理用
  const [companySettingData] = useAtom(CompanySettingDataAtom);
  // rec_type（録音種別の選択情報）のグローバル状態管理用
  const [recType] = useAtom(recTypeAtom);

  /*
  console.log(
    "ProtectedRecording recType:" + JSON.stringify(recType, null, "\t")
  );
  console.log(
    "ProtectedRecording engineType sss:" +
      JSON.stringify(engineType, null, "\t")
  );
  */

  if (
    !SKIP_QUESTIONNAIRES &&
    questionnaire.state !== "skipped" &&
    questionnaire.state !== "responded"
  ) {
    return <Navigate to="../questionnaires" replace />;
  } else if (
    companySettingData.rec_types &&
    companySettingData.rec_types.length > 0 &&
    recType === 0
  ) {
    return <Navigate to="../select-rectype" replace />;
  } else {
    return <Recording />;
  }
}
