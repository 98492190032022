import { BaseAnalysisEngine } from "../engines/baseAnalysisEngine";
import { AnalysisEngineType, isValidAnalysisEngineType } from "../types";
import { useAtom } from "jotai";
import { mi1EnginesAtom } from "../store";
import { useParams } from "react-router-dom";

export function useAssertEngineTypeFromPathParam(): AnalysisEngineType {
  const { engineType } = useParams();
  if (!isValidAnalysisEngineType(engineType))
    throw new Error("Internal Error! Invalid Engine Type");
  return engineType;
}

export function useGetAnalysisEngines(
  engineType: AnalysisEngineType
): BaseAnalysisEngine<unknown>[] {
  const [miEngines] = useAtom(mi1EnginesAtom);

  // eslint-disable-next-line sonarjs/no-small-switch
  switch (engineType) {
    case "Mi1":
      return miEngines;
  }
}
