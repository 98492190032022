import { ReactElement } from "react";
import {
  Box,
  Flex,
  Grid,
  useBreakpointValue,
  useTheme,
} from "@chakra-ui/react";
import { useAtom } from "jotai";
import { phraseVisualizerType, phraseVisualizerTypeAtom } from "../../store";
import { ReactSVG } from "react-svg";
import barImage from "../../assets/images/base/recording_bar.svg";
import circleImage from "../../assets/images/base/recording_circle.svg";
import waveImage from "../../assets/images/base/recording_wave.svg";
import { setSVGFillColor, setSVGStrokeColor } from "../../utils/svgHelper";

interface VisualizerSelectButtonProps {
  visualizerType: phraseVisualizerType;
}

function VisualizerSelectButton({
  visualizerType,
}: Readonly<VisualizerSelectButtonProps>): ReactElement {
  const theme = useTheme();
  const [currentPhraseVisualizerType, setPhraseVisualizerType] = useAtom(
    phraseVisualizerTypeAtom
  );
  const handleClick = (type: phraseVisualizerType): void => {
    setPhraseVisualizerType(type);
  };

  let srcImage = "";
  switch (visualizerType) {
    case "Bar":
      srcImage = barImage;
      break;
    case "Wave":
      srcImage = waveImage;
      break;
    default:
      srcImage = circleImage;
  }

  return (
    <Box
      as="button"
      padding={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      onClick={() => handleClick(visualizerType)}
    >
      <ReactSVG
        src={srcImage}
        beforeInjection={(svg) => {
          svg.setAttribute("width", "200px");
          setSVGFillColor(svg, ".theme_lv1", theme.colors.primary["theme_lv1"]);
          setSVGFillColor(svg, ".theme_lv2", theme.colors.primary["theme_lv2"]);
          setSVGFillColor(svg, ".theme_lv3", theme.colors.primary["theme_lv3"]);
          setSVGFillColor(svg, ".bg_lv1", theme.colors.primary["bg_lv1"]);
          setSVGFillColor(svg, ".accent", theme.colors.primary["accent"]);
          setSVGFillColor(
            svg,
            ".main_text_lv1",
            theme.colors.text["main_text_lv1"]
          );
          setSVGStrokeColor(
            svg,
            ".cls-1, .cls-3, .cls-4",
            theme.colors.primary["theme_lv1"]
          );
          setSVGStrokeColor(svg, ".cls-2", theme.colors.primary["theme_lv2"]);
          if (visualizerType === "Circle") {
            setSVGFillColor(svg, ".cls-3,.cls-4", theme.colors.common["base"]);
          }
          if (visualizerType === "Bar") {
            setSVGFillColor(
              svg,
              ".cls-5,.cls-3,.cls-4",
              theme.colors.common["base"]
            );
            setSVGStrokeColor(
              svg,
              ".cls-3, .cls-4, .cls-5, .cls-6",
              theme.colors.primary["theme_lv1"]
            );
          }
          if (visualizerType === "Wave") {
            setSVGStrokeColor(svg, ".cls-1", theme.colors.primary["accent"]);
            setSVGStrokeColor(svg, ".cls-5", theme.colors.primary["theme_lv3"]);
            setSVGStrokeColor(
              svg,
              ".cls-2, cls-3",
              theme.colors.primary["theme_lv2"]
            );
            setSVGStrokeColor(
              svg,
              ".cls-4, .cls-5, .cls-6, .cls-7",
              theme.colors.primary["theme_lv1"]
            );
            setSVGFillColor(
              svg,
              ".cls-10, .cls-5,.cls-7,.cls-8",
              theme.colors.common["base"]
            );
          }
          if (currentPhraseVisualizerType === visualizerType) {
            setSVGFillColor(svg, ".select", "red");
          }
        }}
      />
    </Box>
  );
}

export function VisualizerSelect(): ReactElement {
  const gridWidth = useBreakpointValue({ base: "100%", md: "500px" });
  const templateColumns = useBreakpointValue({
    base: "1fr",
    sm: "repeat(2, 1fr)",
  });

  return (
    <Flex direction="column" justifyContent="center" alignItems="center">
      <Grid templateColumns={templateColumns} gap={2} width={gridWidth}>
        <VisualizerSelectButton visualizerType="Circle" />
        <VisualizerSelectButton visualizerType="Bar" />
        <VisualizerSelectButton visualizerType="Wave" />
      </Grid>
    </Flex>
  );
}
