import { ReactElement, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { setSVGFillColor } from "../../utils/svgHelper";
import { Flex, Image, useBreakpointValue, useTheme } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { LoginImageInfoType, loginImagePathAtom } from "../../store";

export function BannarImage({
  imageInfo,
}: Readonly<{
  imageInfo: string;
}>): ReactElement {
  const loginInfoJson: LoginImageInfoType = JSON.parse(imageInfo);
  const gridWidth = useBreakpointValue({ base: "100%", md: "400px" });
  const theme = useTheme();
  const [imagePath, setImagePath] = useState("");
  const [loginImagePath, setLoginImagePath] = useAtom(loginImagePathAtom);
  useEffect(() => {
    setImagePath(
      loginInfoJson.login_path !== ""
        ? loginInfoJson.login_path
        : loginImagePath
    );
  }, [loginImagePath, loginInfoJson, setLoginImagePath]);

  // login_noが0なら透明化、1ならテーマカラー反映
  const handleBeforeInjection = (svg: SVGSVGElement): void => {
    const title = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "title"
    );
    title.textContent = "logo";
    svg.prepend(title);
    svg.setAttribute("width", gridWidth || "100%");
    if (loginInfoJson.login_no === 0) {
      setSVGFillColor(svg, "path", "transparent");
    } else if (loginInfoJson.login_no === 1) {
      setSVGFillColor(svg, ".cls-1", theme.colors.primary["theme_lv1"]);
    }
  };

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      paddingTop="3vh"
      paddingBottom="1vh"
    >
      {loginInfoJson.login_no === 0 || loginInfoJson.login_no === 1 ? (
        <ReactSVG src={imagePath} beforeInjection={handleBeforeInjection} />
      ) : (
        <Image src={imagePath} maxHeight="60px" alt="logo" />
      )}
    </Flex>
  );
}
