import { ReactElement } from "react";
import { useTheme } from "@chakra-ui/react";
import { useModifiedTranslation } from "../hooks/useModifiedTranslation";

/**
 * svgの独自設定
 * @param props
 * @constructor
 */
//

// フッターで使用の「解析結果グラフ画面」用アイコン
export function GraphIcon(props: Readonly<{
  height: string | number;
  width: string | number;
  color: string;
}>): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 22.226 20.003"
      className="svg_text"
    >
      <g id="グループ_1" data-name="グループ 1" transform="translate(-2 -3)">
        <path
          id="パス_2018"
          data-name="パス 2018"
          d="M7,23H3.111A1.115,1.115,0,0,1,2,21.892V10.779A1.115,1.115,0,0,1,3.111,9.668H7a1.115,1.115,0,0,1,1.111,1.111V21.892A1.115,1.115,0,0,1,7,23ZM15.058,3H11.168a1.115,1.115,0,0,0-1.111,1.111v17.78A1.115,1.115,0,0,0,11.168,23h3.889a1.115,1.115,0,0,0,1.111-1.111V4.111A1.115,1.115,0,0,0,15.058,3Zm8.057,8.89H19.225A1.115,1.115,0,0,0,18.114,13v8.89A1.115,1.115,0,0,0,19.225,23h3.889a1.115,1.115,0,0,0,1.111-1.111V13A1.115,1.115,0,0,0,23.114,11.89Z"
          transform="translate(0 0)"
          fill={props.color}
        />
      </g>
    </svg>
  );
}

// グラフ内で使用の「普段通り」用アイコン
export function NormalTrendIcon(props: Readonly<{
  height: string | number;
  width: string | number;
}>): ReactElement {
  const t = useModifiedTranslation();
  const theme = useTheme();
  const color_usual = theme.colors.trend["usual"];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 64 37.195"
    >
      <g
        id="グループ_50"
        data-name="グループ 50"
        transform="translate(-173.455 -232.534)"
      >
        <text
          id="普段通り"
          transform="translate(205.455 264.729)"
          fill={color_usual}
          fontSize="16"
          fontFamily="noto sans jp"
          fontWeight="700"
        >
          <tspan x="-32" y="0">
            {t("Graph.common.normal")}
          </tspan>
        </text>
        <path
          id="trending_flat"
          d="M30.789,13.092,26.632,8.934a.746.746,0,0,0-1.281.522v2.667H4.49a1.49,1.49,0,0,0,0,2.98H25.351V17.77a.738.738,0,0,0,1.267.522l4.157-4.157A.733.733,0,0,0,30.789,13.092Z"
          transform="translate(188.455 223.826)"
          fill={color_usual}
        />
      </g>
    </svg>
  );
}

// グラフ内で使用の「いい調子」用アイコン
export function UpTrendIcon(props: Readonly<{
  height: string | number;
  width: string | number;
}>): ReactElement {
  const t = useModifiedTranslation();
  const theme = useTheme();
  const color_good = theme.colors.trend["good"];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 64 40.584"
    >
      <g
        id="グループ_11"
        data-name="グループ 11"
        transform="translate(-173.455 -229.145)"
      >
        <text
          id="いい調子"
          transform="translate(205.455 264.729)"
          fill={color_good}
          fontSize="16"
          fontFamily="noto sans jp"
          fontWeight="700"
        >
          <tspan x="-32" y="0">
            {t("Graph.common.good")}
          </tspan>
        </text>
        <path
          id="trending_up"
          d="M23.064,7.216l2.06,2.06-6.981,6.981-4.707-4.707a1.425,1.425,0,0,0-2.017,0l-8.583,8.6a1.426,1.426,0,1,0,2.017,2.017l7.568-7.582,4.707,4.707a1.425,1.425,0,0,0,2.017,0l8-7.982,2.06,2.06a.714.714,0,0,0,1.216-.5V6.715a.688.688,0,0,0-.7-.715H23.579a.715.715,0,0,0-.515,1.216Z"
          transform="translate(189.038 223.145)"
          fill={color_good}
        />
      </g>
    </svg>
  );
}

// グラフ内で使用の「疲れ気味」用アイコン
export function DownTrendIcon(props: Readonly<{
  height: string | number;
  width: string | number;
}>): ReactElement {
  const t = useModifiedTranslation();
  const theme = useTheme();
  const color_bad = theme.colors.trend["bad"];

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 64 40.58"
    >
      <g
        id="グループ_49"
        data-name="グループ 49"
        transform="translate(-173.455 -229.149)"
      >
        <text
          id="疲れ気味"
          transform="translate(205.455 264.729)"
          fill={color_bad}
          fontSize="16"
          fontFamily="noto sans jp"
          fontWeight="700"
        >
          <tspan x="-32" y="0">
            {t("Graph.common.bad")}
          </tspan>
        </text>
        <path
          id="trending_down"
          d="M23.064,21.785l2.06-2.061-6.981-6.985-4.707,4.709a1.424,1.424,0,0,1-2.017,0l-8.583-8.6A1.427,1.427,0,0,1,4.853,6.826l7.568,7.587L17.127,9.7a1.424,1.424,0,0,1,2.017,0l8,7.988L29.2,15.63a.714.714,0,0,1,1.216.5v6.141a.709.709,0,0,1-.715.716H23.565a.7.7,0,0,1-.5-1.2Z"
          transform="translate(189.038 222.741)"
          fill={color_bad}
        />
      </g>
    </svg>
  );
}
