import { ReactElement, useState } from "react";
import { Grid, Box } from "@chakra-ui/react";
import Measure, { ContentRect } from "react-measure";
import clsx from "clsx";

/**
 * 解析結果グラフ画面 レイアウトパーツ: 期間指定タブ
 * @param props
 * @constructor
 */

interface SegmentedControlProps {
  onChanged: (value: number) => void;
  inActiveColor: string;
  activeColor: string;
  activeTextColor: string;
  inActiveTextColor: string;
  values: Array<string>;
  value: number;
}

export default function SegmentedControl(
  props: Readonly<SegmentedControlProps>
): ReactElement {
  const [componentWidth, setComponentWidth] = useState(0);
  const handleResize = (contentRect: ContentRect): void => {
    setComponentWidth(contentRect.bounds ? contentRect.bounds.width : 0);
    console.log("componentWidth", componentWidth);
  };

  const handleAlignment = (value: number): void => {
    if (value !== null) {
      props.onChanged(value);
    }
  };

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <Box
          sx={{
            ".analysis_term": {
              height: "0px",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "4px",
            },
            ".analysis_term_item": {
              backgroundColor: "primary.theme_lv3",
              color: "primary.theme_lv1",
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center",
            },

            ".analysis_term_item.active": {
              backgroundColor: "primary.theme_lv1",
              color: "white",
            },

            ".analysis_term_item:nth-of-type(1)": {
              borderTopLeftRadius: "999px",
              borderBottomLeftRadius: "999px",
            },
            ".analysis_term_item:nth-of-type(3)": {
              borderTopRightRadius: "999px",
              borderBottomRightRadius: "999px",
            },
          }}
        >
          <Box ref={measureRef} className="analysis_term">
            <Grid
              templateColumns={`repeat(${props.values.length}, 1fr)`}
              w="83vw"
              marginLeft="4vw"
              marginRight="4vw"
              maxW={648}
              h={40}
            >
              {props.values.map((value, index) => (
                <Box
                  className={clsx({
                    analysis_term_item: true,
                    active: index === props.value,
                  })}
                  height={
                    40 -
                    (componentWidth ? (10 * (648 - componentWidth)) / 648 : 40)
                  }
                  key={index}
                  onClick={() => handleAlignment(index)}
                >
                  {value}
                </Box>
              ))}
            </Grid>
          </Box>
        </Box>
      )}
    </Measure>
  );
}
