import { ReactElement } from "react";
import { Modal, ModalContent, ModalOverlay, Spinner } from "@chakra-ui/react";

type Props = {
  isOpen: boolean;
};

export function LoadingOverlay(props: Readonly<Props>): ReactElement {
  return (
    <Modal
      isOpen={props.isOpen}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={() => undefined}
      isCentered
    >
      <ModalOverlay backdropFilter="blur(5px)" />
      <ModalContent bgColor="transparent" boxShadow="none" alignItems="center">
        <Spinner color="white" size="xl" />
      </ModalContent>
    </Modal>
  );
}
