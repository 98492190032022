import { useToast, AlertStatus } from "@chakra-ui/react";
import { useCallback } from "react";
import axios from "axios";
import { AppApiError, isObject, isValidAppApiError } from "../types";
import { useModifiedTranslation } from "./useModifiedTranslation";

function getApiErrorMessage(
  error: AppApiError,
  fallbackMessage: string,
  t: (key: string) => string
): string {
  let message: string;
  if (isObject(error.detail)) {
    const tKey = `ApiError.${error.detail.type}`;
    message = t(tKey);
    if (message === tKey) {
      message = error.detail.msg || fallbackMessage;
    }
  } else if (Array.isArray(error.detail)) {
    const firstDetail = error.detail[0];
    if (firstDetail && isObject(firstDetail)) {
      message = firstDetail.msg || fallbackMessage;
    } else if (typeof firstDetail === "string") {
      message = firstDetail;
    } else {
      message = fallbackMessage;
    }
  } else {
    message = error.detail;
  }
  return message;
}

type ErrorToast = (e: unknown, toastStatus?: AlertStatus) => void;

export function useToastMessage(): ErrorToast {
  const toast = useToast();
  const t = useModifiedTranslation();

  const getToastMessage = useCallback(
    (err: unknown): { title: string; message?: string } => {
      if (axios.isAxiosError(err)) {
        const status = err.response?.status;
        const responseData = err.response?.data;

        let message: string | undefined = undefined;
        if (isValidAppApiError(responseData)) {
          /*
           * パスワード変更（/account/password"）時はレスポンスのメッセージを明示的に返却
           */
          if (
            err.config?.url === "/account/password" &&
            status &&
            [400, 401, 403, 404, 422, 500, 504].includes(status)
          ) {
            message = t(`ApiError.account_password.${status}`);
          } else {
            message = getApiErrorMessage(responseData, err.message, t);
          }
        } else {
          message = err.message;
        }

        return {
          title: status
            ? `${status}: ${t("ApiError." + status)}`
            : `${err.code}`,
          message,
        };
      }

      if (err instanceof Error) {
        return {
          title: err.name,
          message: err.message,
        };
      }

      if (typeof err === "string") {
        return {
          title: err,
        };
      }

      return {
        title: "Internal Error",
      };
    },
    [t]
  );

  return useCallback(
    (err: unknown, toastStatus = "error") => {
      const { title, message } = getToastMessage(err);

      toast({
        status: toastStatus,
        containerStyle: { whiteSpace: "pre-wrap" },
        isClosable: true,
        duration: 1500,
        title: title,
        description: message,
      });
    },
    [toast, getToastMessage]
  );
}
