import { ReactElement } from "react";
import PropTypes from "prop-types";
import {
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { scoreAverageAtom } from "./HomeAtoms";
import { Box, Text, useTheme } from "@chakra-ui/react";
import {
  CustomXAxisTick,
  getTicks,
  CustomDot,
  averageLabelPosition,
} from "../ResultCommon/ChartCustom";
import { ResultChartData } from "../../../types";
import { useModifiedTranslation } from "../../../hooks/useModifiedTranslation";

type ReferenceDataType = {
  lineY: number;
  areaTop: number;
  areaBottom: number;
  areaColor: string;
};

/**
 * ホーム画面のグラフ
 * 解析結果グラフ画面 グラフ内でマーカーにマウスオーバー時のツールチップ表示
 * @constructor
 */
export const CustomTooltip = (
  props: TooltipProps<number, string>
): ReactElement | null => {
  const [[vitalityAve, mentalActivityAve, mi1Ave]] = useAtom(scoreAverageAtom);
  const payload = props.payload;
  const t = useModifiedTranslation();
  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  if (
    props.active &&
    payload !== null &&
    payload !== undefined &&
    payload.length > 0
  ) {
    const datingType = payload[0]?.payload?.datingType;
    const date = payload[0]?.payload?.date;
    const ValueResult = (): ReactElement => (
      <>
        {payload.map((data, key) => (
          <div key={key}>
            <Text fontFamily="OsWald" fontWeight="300" display="inline-flex">
              {`${data.name} : `} {`${data.value?.toFixed(1) || ""}`}
            </Text>
            {key < payload.length - 1 && <br />}
          </div>
        ))}
      </>
    );

    const average_text = t("Graph.common.average");

    // console.log("payload:" + JSON.stringify(payload, null, "\t"));

    const AverageResult = (): ReactElement => (
      <>
        {payload.map((data, key) => (
          <div key={key}>
            <Text fontFamily="OsWald" fontWeight="300" display="inline-flex">
              {`${data.name}`}
              {average_text}
              {`: `}
              {data.name === t("Graph.common.vitality") &&
                (vitalityAve * 100).toFixed(1)}
              {data.name === t("Graph.common.mentalActivity") &&
                (mentalActivityAve * 100).toFixed(1)}
              {data.name === t("Graph.common.mi1Result") && mi1Ave.toFixed(1)}
            </Text>
            {key < payload.length - 1 && <br />}
          </div>
        ))}
      </>
    );

    // ツールチップ先頭行の日時
    const DateResult = (): ReactElement => (
      <>
        <Text style={{ display: "inline-flex" }} as="div">
          <Box fontFamily="Oswald" letterSpacing={0.5} fontWeight={300}>
            {datingType === "year"
              ? `${dayjs(date).format("YYYY/M")}`
              : `${dayjs(date).format("YYYY/M/D")}`}
          </Box>
          {datingType !== "year" && (
            <Box
              fontFamily="Oswald"
              fontSize="0.8rem"
              letterSpacing={0.5}
              fontWeight={300}
            >
              <Box
                style={{ height: "90%" }}
                display="flex"
                alignItems="flex-end"
              >
                <Box>{`${dayjs(date).format("(ddd)")}`}</Box>
              </Box>
            </Box>
          )}
          <Box fontFamily="Oswald" letterSpacing={0.5} fontWeight={300}>
            {/* の平均値 */}
            {datingType === "year"
              ? t("Graph.common.averageOf")
              : `${dayjs(date).format(" HH:mm:ss")}`}
          </Box>
        </Text>
      </>
    );

    return (
      <div
        style={{
          backgroundColor: backgroundColor,
          padding: 4,
          textAlign: "left",
        }}
      >
        <DateResult />
        <br />
        <ValueResult />
        <br />
        <AverageResult />
      </div>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  payload: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.number,
      payload: PropTypes.object,
    })
  ),
  active: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export function HomeChartBody(
  props: Readonly<{
    data: ResultChartData[];
    reference: ReferenceDataType;
    width: number;
    height: number;
    tickArea: number[];
    name: string;
    dataKey: string;
    hasData: boolean;
    outputStenScore: boolean;
  }>
): ReactElement {
  /*
  console.log(
    "HomeChartBody height:" + JSON.stringify(props.height, null, "\t")
  );
  console.log("HomeChartBody width:" + JSON.stringify(props.width, null, "\t"));
   */

  const t = useModifiedTranslation();

  // const labelValueName = props.outputStenScore ? "平均" : "あなたの平均";
  const labelValueName = props.outputStenScore
    ? t("Graph.common.average")
    : t("Graph.common.averageYour");
  const yAxisWidthSize = props.outputStenScore ? 16 : 0;

  let calculationWidth;
  if (props.outputStenScore) {
    // calculationWidth = props.width > 620 ? 500 : props.width - 100;
    calculationWidth = props.width > 620 ? 530 : props.width - 80;
  } else {
    calculationWidth = props.width > 620 ? 400 : props.width - 170;
  }

  const yAxisTickFontSize = props.outputStenScore ? 10 : 0;
  const noDataY1 = props.dataKey === "mi1_result" ? 10 : 100;
  const noDataY2 = props.dataKey === "mi1_result" ? 1 : 0;
  const referenceLineY =
    props.dataKey === "mi1_result"
      ? props.reference.lineY
      : props.reference.lineY * 100;
  const theme = useTheme();
  const tickColor = theme.colors.common_text["gray"];
  const referenceAreaColor = "#808080";
  const referenceLineColor = theme.colors.graph["referenceLine"];
  return (
    <>
      <ResponsiveContainer
        height={props.height > 0 ? props.height : 90}
        width={calculationWidth}
      >
        <ComposedChart
          data={props.data}
          margin={{ top: 8, right: 8, left: 8, bottom: 0 }}
        >
          <YAxis
            domain={["dataMin", "dataMax"]}
            ticks={props.tickArea}
            axisLine={false}
            tickLine={false}
            width={yAxisWidthSize}
            tick={{
              fontSize: yAxisTickFontSize,
              fill: tickColor,
              fontFamily: "Oswald",
            }}
          />
          <XAxis
            tickLine={false}
            tick={<CustomXAxisTick />}
            ticks={getTicks(dayjs().startOf("day").unix() * 1000, 7)}
            dataKey="date"
            domain={["dataMin", "dataMax"]}
            interval={0}
            type="number"
          />
          <CartesianGrid vertical={false} />

          {!props.hasData && (
            <ReferenceArea
              y1={noDataY1}
              y2={noDataY2}
              fill="common.base"
              label={{
                value: "NO DATA",
                fontFamily: "Oswald",
                stroke: referenceAreaColor,
                fill: referenceAreaColor,
                fontSize: "26px",
                position: "center",
              }}
            />
          )}

          {!props.outputStenScore &&
            props.reference.areaTop !== 0 &&
            props.hasData && (
              <ReferenceArea
                y1={props.reference.areaTop}
                y2={props.reference.areaBottom}
                fill={`${props.reference.areaColor}33`}
                strokeOpacity={0}
              />
            )}

          <Tooltip content={<CustomTooltip />} />
          <Line
            connectNulls
            name={props.name}
            strokeLinecap="round"
            isAnimationActive={false}
            dot={
              <CustomDot
                dotColor={props.reference.areaColor}
                strokeWidth="2"
                thresholdAlertValue={props.reference.areaBottom}
              />
            }
            dataKey={props.dataKey}
            strokeWidth={1}
            fillOpacity={1}
            stroke={props.reference.areaColor}
          />
          {!props.outputStenScore && props.reference.lineY !== 0 && (
            <ReferenceLine
              y={referenceLineY}
              strokeWidth={0.5}
              stroke={referenceLineColor}
            >
              <Label
                fontFamily="noto sans jp"
                fontSize={7}
                fill={referenceLineColor}
                value={labelValueName}
                position={averageLabelPosition(
                  props.data,
                  referenceLineY,
                  props.dataKey
                )}
              />
            </ReferenceLine>
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
}
