import {
  Box,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import { volumeToDecibel } from "../../utils/calcVolume";
import {
  CALIBRATION_MIN_LOG_POWER,
  CALIBRATION_MAX_LOG_POWER,
} from "../../constants";

const LOG_POWER_STEPS = 0.01;

export function ThresholdSlider(props: Readonly<{
  averagePower: number;
  maxPower: number;
  logDetectVolume: number;
  leftComment?: string;
  rightComment?: string;
  onChangeLogDetectVolume: (val: number) => void;
}>): ReactElement {
  const leftComment = props.leftComment ?? "MIN";
  const rightComment = props.rightComment ?? "MAX";
  const logAveragePower = volumeToDecibel(props.averagePower);
  const logMaxPower = volumeToDecibel(props.maxPower);
  return (
    <Box position="relative" w="full">
      <Slider
        value={logMaxPower < 100 ? logAveragePower : CALIBRATION_MAX_LOG_POWER}
        min={CALIBRATION_MIN_LOG_POWER}
        max={CALIBRATION_MAX_LOG_POWER}
        step={LOG_POWER_STEPS}
        height={5}
        mb="20px"
      >
        <SliderTrack bgColor="primary.bg_lv1">
          <SliderFilledTrack bgColor="primary.theme_lv1" />
        </SliderTrack>
        <SliderMark
          value={CALIBRATION_MIN_LOG_POWER}
          mt={5}
          ml={-2.5}
          fontSize="sm"
        >
          {leftComment}
        </SliderMark>
        <SliderMark
          value={CALIBRATION_MAX_LOG_POWER}
          mt={5}
          ml={-5}
          fontSize="sm"
        >
          {rightComment}
        </SliderMark>
      </Slider>
      <Box position="absolute" top={0} width="full">
        <Slider
          min={CALIBRATION_MIN_LOG_POWER}
          max={CALIBRATION_MAX_LOG_POWER}
          step={LOG_POWER_STEPS}
          height={5}
          value={props.logDetectVolume}
          onChange={props.onChangeLogDetectVolume}
        >
          <SliderTrack bgColor="transparent">
            <SliderFilledTrack bgColor="transparent" />
          </SliderTrack>
          <SliderThumb
            width={2.5}
            height={5}
            rounded={3}
            bgColor="white"
            borderWidth={2}
            borderColor="primary.theme_lv1"
            shadow="none"
          />
        </Slider>
      </Box>
    </Box>
  );
}
