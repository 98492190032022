import { jwtDecode, JwtHeader } from "jwt-decode";
import { DecodedAccessToken } from "../store";

/*
 * クエリログイン用のアクセストークンのデコード
 */
export const decodeJwtHeader = (token: string): JwtHeader => {
  return jwtDecode(token, { header: true });
};

/*
 * 一般ユーザー用のアクセストークンのデコード
 */
export const decodeJwt = (token: string): DecodedAccessToken => {
  return jwtDecode<DecodedAccessToken>(token);
};
