import { ReactElement, useEffect } from "react";
import { decodeJwt, decodeJwtHeader } from "../utils/decodeJwt";
import { Splash } from "../components/atoms/InnerPage/Splash";
import { useAtom } from "jotai";
import {
  accessTokenAtom,
  accessTokenInfoAtom,
  companyAccessTokenAtom,
  queryLoginDataParamAtom,
  recTypeAtom,
  showLoginAlertDialogAtom,
} from "../store";
import { useSetSetting } from "../utils/setting";
import { useSaveTokenToStorage } from "../utils/token";
import { InvalidTokenError } from "jwt-decode";

/*
 * クエリログイン
 */
export function QueryLogin(): ReactElement {
  const [company_token] = useAtom(companyAccessTokenAtom);
  // console.log("inQueryLoginRoot");
  const [, setLoginData] = useAtom(queryLoginDataParamAtom);
  const [, setAccessToken] = useAtom(accessTokenAtom);
  const [, setRecType] = useAtom(recTypeAtom);
  const [showDialog] = useAtom(showLoginAlertDialogAtom);
  const [accessTokenInfo] = useAtom(accessTokenInfoAtom);
  const setSettingInfo = useSetSetting();
  const saveToken = useSaveTokenToStorage();

  useEffect(() => {
    if (!company_token) return;

    let companyId, employeeId, recType;
    let companyAccessToken: string | undefined;
    let companyExpiresIn: number | undefined;

    try {
      const objCompanyAccessTokenInfo = JSON.parse(company_token);
      const decodedHeader = decodeJwtHeader(
        objCompanyAccessTokenInfo.access_token
      );

      if (decodedHeader && decodedHeader.typ === "JWT") {
        companyAccessToken = objCompanyAccessTokenInfo.access_token as string;
        companyExpiresIn = objCompanyAccessTokenInfo.expires_in as number;
        const decoded = decodeJwt(companyAccessToken);
        if (decoded) {
          companyId = decoded.companyEmail;
          employeeId = decoded.employeeEmail;
          recType = decoded.recType;
        }
      }
    } catch (err) {
      if (err instanceof InvalidTokenError) {
        console.log(`decode error. ${err.message}`);
        return;
      }
      if (err instanceof Error) {
        console.log(`Runtime error. ${err.message}`);
        return;
      }
    }

    if (!companyId || !employeeId || !companyAccessToken || !companyExpiresIn) {
      console.error("company_tokenが異常な形式です");
      return;
    }

    const queryLoginUserData = {
      companyId,
      companyAccessToken,
      employeeId,
      recType,
    };

    const accessToken = {
      access_token: companyAccessToken,
      expires_in: companyExpiresIn,
    };

    // ここからログイン処理実装
    setLoginData(queryLoginUserData);
    if (queryLoginUserData?.recType) {
      setRecType(queryLoginUserData.recType);
    }
    setAccessToken(companyAccessToken);
    saveToken(accessToken);
    if (!showDialog) {
      setSettingInfo(employeeId);
    }
  }, [
    saveToken,
    setSettingInfo,
    company_token,
    setAccessToken,
    showDialog,
    setLoginData,
    setRecType,
  ]);

  return <>{accessTokenInfo && <Splash />}</>;
}
