import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, VStack, useTheme } from "@chakra-ui/react";
import { Layout } from "../../../components/atoms/Layout";
import { useAtom } from "jotai";
import { CompanySettingDataAtom, recTypeAtom } from "../../../store";

export function SelectRecType(): ReactElement {
  const [companySettingData] = useAtom(CompanySettingDataAtom);
  const [, setRecType] = useAtom(recTypeAtom);

  const theme = useTheme();
  const backgroundColor = theme.colors.primary["bg_lv1"];

  useEffect(() => {
    document.body.style.backgroundColor = backgroundColor;
    return () => {
      document.body.style.backgroundColor = "white";
    };
  }, [backgroundColor]);

  /*
  console.log(
    "companySettingData:" + JSON.stringify(companySettingData, null, "\t")
  );
   */

  const navigate = useNavigate();

  function RecordingTypeButton(props: Readonly<{
    title: string;
    onClick: () => void;
    disabled?: boolean;
  }>): ReactElement {
    return (
      <Button
        disabled={props.disabled}
        onClick={props.onClick}
        width="full"
        variant="btn_primary"
        size="lg"
      >
        {props.title}
      </Button>
    );
  }

  const selectRecType = useCallback(
    (val: number): void => {
      setRecType(val);
      navigate("../recording");
    },
    [setRecType, navigate]
  );

  const recArray: JSX.Element[] = useMemo(() => {
    return (
      companySettingData.rec_types?.map((val) => (
        <Box width="full" key={val.rec_type}>
          <RecordingTypeButton
            title={val.label}
            onClick={() => selectRecType(val.rec_type)}
          />
        </Box>
      )) ?? []
    );
  }, [companySettingData.rec_types, selectRecType]);

  const stackRef = useRef<HTMLDivElement>(null);
  const [justifyContent, setJustifyContent] = useState("center");

  useEffect(() => {
    const checkOverflow = (): void => {
      const stack = stackRef.current;
      if (stack) {
        const isOverflowing = stack.scrollHeight > stack.clientHeight;
        setJustifyContent(isOverflowing ? "flex-start" : "center");
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [recArray]);

  return (
    <Layout h="100vh">
      <VStack ref={stackRef} justify={justifyContent} h="full" gap={12}>
        {recArray.map((content) => content)}
      </VStack>
    </Layout>
  );
}
