import { useTranslation } from "react-i18next";
import { useToastMessage } from "../hooks/useToastMessage";
import {
  AccessToken,
  LoginErrorCode,
  TokenEngineType,
  isObject,
  isValidAppApiError,
  AuthData,
  LoginMethod,
} from "../types";
import {
  AccessTokenInfo,
  accessTokenInfoAtom,
  outputStenScoreAtom,
} from "../store";
import { useCallback } from "react";
import { useAtom } from "jotai";
import { ENABLED_TOKEN_ENGINE_TYPES } from "../environments";
import { appAxios } from "./appAxios";
import { decodeJwt } from "./decodeJwt";
import axios, { AxiosResponse } from "axios";
import dayjs from "dayjs";
import { useModifiedTranslation } from "../hooks/useModifiedTranslation";

type AccessTokenRequest = {
  user_id: string;
  password: string;
  engine_types: TokenEngineType[];
};

type GetAccessToken = <T extends LoginMethod>(
  method: T,
  onInvalidUserInfo: (errorCode: LoginErrorCode) => void,
  data: AuthData[T]
) => Promise<AccessToken | false>;

export function useGetAccessToken(): GetAccessToken {
  const toastMessage = useToastMessage();
  const { i18n } = useTranslation();
  const t = useModifiedTranslation();
  // STENスコアの出力有無のグローバル状態管理用
  const [, setOutputStenScore] = useAtom(outputStenScoreAtom);

  const requestAuthToken = async (
    authMethod: LoginMethod,
    authData: unknown
  ): Promise<AxiosResponse> => {
    switch (authMethod) {
      case LoginMethod.LoginForm: {
        const data = authData as AuthData[LoginMethod.LoginForm];
        const accessTokenRequestParam: AccessTokenRequest = {
          user_id: data.user_id,
          password: data.password,
          engine_types: ENABLED_TOKEN_ENGINE_TYPES,
        };
        return await appAxios.post("/token", accessTokenRequestParam);
      }

      case LoginMethod.GoogleAuth: {
        const data = authData as AuthData[LoginMethod.GoogleAuth];
        return await appAxios.post("/oauth/google/token ", data);
      }

      case LoginMethod.TwoocaAuth: {
        const data = authData as AuthData[LoginMethod.TwoocaAuth];
        return await appAxios.post("/auth/twooca/token", data);
      }
    }
  };

  const getLoginErrorCode = (method: LoginMethod): LoginErrorCode => {
    switch (method) {
      case LoginMethod.LoginForm:
        return "INVALID_TOKEN";

      case LoginMethod.GoogleAuth:
        return "INVALID_GOOGLE_TOKEN";

      case LoginMethod.TwoocaAuth:
        return "INVALID_TWOOCA_TOKEN";
    }
  };

  return useCallback(
    async (method, onInvalidUserInfo, authData) => {
      try {
        const response = await requestAuthToken(method, authData);
        const decodedAccessToken = decodeJwt(response.data.access_token);
        // console.log("decodedAccessToken:" + JSON.stringify(decodedAccessToken,null,'\t'));
        // STENスコアの出力有無の判定と状態保持
        const outputStenScore =
          decodedAccessToken?.engineTypes?.mi1?.engine_type === "mi1" &&
          decodedAccessToken.engineTypes.mi1.options?.output_sten_score;
        // console.log("outputStenScore:" + JSON.stringify(outputStenScore,null,'\t'));
        setOutputStenScore(outputStenScore);

        if (decodedAccessToken?.userType !== "employee") {
          toastMessage(t("ApiError.403"));
          return false;
        } else {
          return response.data;
        }
      } catch (e) {
        console.error("Get Access Token Error!", e);
        if (!axios.isAxiosError(e)) {
          toastMessage("Internal Error!");
          return false;
        }

        const errData = e.response?.data;
        if (!isValidAppApiError(errData) || !isObject(errData.detail)) {
          toastMessage(e);
          return false;
        }

        if (errData.detail.type === "auth_error.invalid_input") {
          const errorCode = getLoginErrorCode(method);
          onInvalidUserInfo(errorCode);
        } else if (
          method == LoginMethod.LoginForm &&
          errData.detail.type === "auth_error.locked_temporary"
        ) {
          const data = authData as AuthData[LoginMethod.LoginForm];
          toastMessage(e);
          appAxios
            .put("/account/temporary_locked/unlock/request", {
              user_id: data.user_id,
              app_url: `${location.origin}/${i18n.resolvedLanguage}/account-unlocked`,
              language: i18n.resolvedLanguage,
            })
            .catch((e) => {
              console.error("Unlock to Temporary Locked Error!", e);
              toastMessage(e);
            });
        } else {
          toastMessage(e);
        }

        return false;
      }
    },
    [i18n.resolvedLanguage, t, toastMessage, setOutputStenScore]
  );
}

type SaveTokenToStorage = (data: AccessToken) => void;
export function useSaveTokenToStorage(): SaveTokenToStorage {
  const [, setAccessToken] = useAtom(accessTokenInfoAtom);

  return useCallback(
    (data) => {
      const now = dayjs();
      const tokenInfo: AccessTokenInfo = {
        access_token: data.access_token,
        expired_at: now.add(data.expires_in, "second").toISOString(),
      };
      setAccessToken(tokenInfo);
    },
    [setAccessToken]
  );
}
