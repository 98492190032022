import { ReactElement } from "react";
import dayjs from "dayjs";
import { useTheme } from "@chakra-ui/react";
import { ResultChartData, CustomDotType } from "../../../types";
import { XAxisProps } from "recharts";
interface CustomXAxisTickProps extends XAxisProps {
  payload?: {
    value: number;
  };
}

export function getTicks(now: number, dayCount: number): number[] {
  const millisecPerDay = 86400000;
  const ticks: number[] = [];
  for (let i = dayCount; i > 0; i--) {
    ticks.push(now - millisecPerDay * i);
  }

  ticks.push(now);
  ticks.push(now + millisecPerDay + 1); // 最後の値だけ表示を隠すためのトリック
  return ticks;
}

/**
 * rechartsのxaxisで本日だけ太文字するするカスタムtick
 */
export const CustomXAxisTick = (props: CustomXAxisTickProps): ReactElement => {
  let textColor;
  let fillOpacity = 1;
  const now = dayjs();
  const theme = useTheme();
  if (
    props.payload &&
    props.payload.value &&
    dayjs(props.payload.value).isSame(now, "D")
  ) {
    textColor = theme.colors.common_text["gray"];
  } else if (
    props.payload &&
    props.payload.value &&
    props.payload.value % 1000 == 1
  ) {
    fillOpacity = 0;
  } else {
    textColor = theme.colors.common_text["gray"];
  }

  return (
    <g transform={`translate(${props.x},${props.y})`}>
      <text
        x={4}
        y={0}
        dy={16}
        fontSize={12}
        fontWeight={300}
        fontFamily="Oswald"
        textAnchor="end"
        fill={textColor}
        fillOpacity={fillOpacity}
      >
        {props?.payload?.value ? dayjs(props.payload.value).format("D") : null}
      </text>
    </g>
  );
};

export const CustomDot = (props: CustomDotType): ReactElement => {
  const cx = props.cx;
  const cy = props.cy;
  const isMi1ResultUnReliability =
    props.payload?.mi1_result_reliability === false;
  const isMentalActivityReliability =
    props.payload?.mental_activity_reliability === false;
  const theme = useTheme();
  const mi1resultColor = theme.colors.mi1Result["primary"];

  if (cx === null || cy === null) {
    return <></>;
  }

  let figure = "circle";
  let dotColor = props.dotColor;
  let fillColor = props.dotColor;

  if (props.dataKey === "mi1_result" && isMi1ResultUnReliability) {
    fillColor = theme.colors.common.base;
  } else if (
    props.dataKey === "mi1_result" &&
    props.thresholdAlertValue &&
    Number(props.value) < props.thresholdAlertValue
  ) {
    dotColor = mi1resultColor;
    figure = "square";
  } else if (
    props.dataKey === "mental_activity100" &&
    isMentalActivityReliability
  ) {
    fillColor = theme.colors.common.base;
  }

  if (figure === "square") {
    const x = cx ? cx - 2.5 : cx;
    const y = cy ? cy - 2.5 : cy;
    return (
      <svg>
        <rect
          x={x}
          y={y}
          height={5}
          width={5}
          stroke={dotColor}
          fill={fillColor}
          strokeWidth={props.strokeWidth}
        />
      </svg>
    );
  } else {
    return (
      <svg>
        <circle
          cx={cx}
          cy={cy}
          r={props.r}
          stroke={dotColor}
          fill={fillColor}
          strokeWidth={props.strokeWidth}
        />
      </svg>
    );
  }
};

export function averageLabelPosition(
  data: ResultChartData[],
  referenceLineY: number,
  key: string
): "insideBottomLeft" | "insideTopLeft" {
  const detectPositionNumber = data.slice(0, 3).reduce((acc, singleData) => {
    if (!(key in singleData)) return acc - 1;
    return singleData[key] < referenceLineY ? acc - 1 : acc + 1;
  }, 0);
  return detectPositionNumber < 0 ? "insideBottomLeft" : "insideTopLeft";
}
