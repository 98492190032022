import { ReactElement, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Flex,
  Center,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { IoSettingsSharp, IoHomeSharp, IoMic, IoLogOut } from "react-icons/io5";
import { useAtom } from "jotai";
import {
  accessTokenInfoAtom,
  recTypeAtom,
  footerIndexAtom,
} from "../../../store";
import { ANALYSIS_ENGINE, SKIP_QUESTIONNAIRES } from "../../../environments";
import { getSelectableEngineList } from "../../../pages/engines";
import { GraphIcon } from "../../../assets/Icons";
import { useModifiedTranslation } from "../../../hooks/useModifiedTranslation";

/*
 * ログイン後のフッター
 */
export function Footer(): ReactElement {
  const t = useModifiedTranslation();
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const dialogCancelRef = useRef(null);
  const [, setRecType] = useAtom(recTypeAtom);

  const [tokenInfo] = useAtom(accessTokenInfoAtom);

  const [footerIndex, setFooterIndex] = useAtom(footerIndexAtom);
  const innerFooterIndex = useRef(0);

  const navigate = useNavigate();

  const isFooterclick = useRef(false);

  const [footerItems, setBoxes] = useState([
    { id: 0, label: "home" },
    { id: 1, label: "analysis-result" },
    { id: 2, label: "record" },
    { id: 3, label: "setting" },
    { id: 4, label: "logout" },
  ]);

  const handleClick = (index: number): void => {
    if (index !== 4) {
      setFooterIndex(index);
    }
    innerFooterIndex.current = index;
    isFooterclick.current = true;

    /*
    console.log("index:" + index);
    console.log("footerIndex:" + JSON.stringify(footerIndex, null, "\t"));
     */
    const updatedBoxes = footerItems.map((footerItem) => {
      return footerItem;
    });
    setBoxes(updatedBoxes);

    if (index == 4) {
      setIsOpenDialog(true);
    }
  };

  useEffect(() => {
    if (!isFooterclick.current) return;

    isFooterclick.current = false;

    const getAnalyzeUrl = (): string => {
      if (ANALYSIS_ENGINE) {
        const page = SKIP_QUESTIONNAIRES ? "recording" : "questionnaires";
        return `../engines/${ANALYSIS_ENGINE}/${page}`;
      }

      const url_list = tokenInfo
        ? getSelectableEngineList(tokenInfo.access_token)
        : "";
      if (url_list.length == 1) {
        return "../engines/" + url_list[0] + "/questionnaires";
      }

      return "../engines";
    };

    const timer = setTimeout(() => {
      switch (innerFooterIndex.current) {
        case 0:
          navigate("../home");
          break;

        case 1:
          navigate("../analysis-result");
          break;

        case 2:
          setRecType(0);
          navigate(getAnalyzeUrl());
          setFooterIndex(0);
          break;

        case 3:
          navigate("../setting");
          break;

        case 4:
          setIsOpenDialog(true);
          break;
      }
    }, 10);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [footerIndex]);

  return (
    <>
      <Box
        sx={{
          ".footer_wrapper": {
            position: "fixed",
            bottom: 0,
            width: "100%",
            height: "90px",
            display: "flex",
          },
          ".footer": {
            width: "100vw",
            borderRadius: "30px 30px 0 0",
            paddingBottom: "20px",
            boxShadow: "0px 5px 10px 10px result.meter_text_lv2",
          },
          ".icon_basic": {
            opacity: "0.5",
            width: "75px",
          },

          ".icon_basic:nth-of-type(3)": {
            fontWeight: "600",
            color: "white",
            opacity: "1",
            background: "primary.theme_lv1",
            height: "50px",
            width: "50px",
            // marginTop: "5px",
            paddingTop: "13px",
            paddingLeft: "1px",
            borderRadius: "50%",
            marginLeft: "15px",
            marginRight: "15px",
          },

          ".icon_basic:nth-of-type(5)": {
            fontSize: "30px",
          },

          ".active": {
            opacity: "1",
          },
          ".icon_basic svg": {
            margin: "auto",
          },
          ".svg_text": {
            fill: "currentColor",
          },
        }}
      >
        <Box className="footer_wrapper" bg="common.base">
          <Box className="footer" bg="common.base">
            <Center>
              <Flex
                width="100%"
                marginTop="15px"
                marginLeft="12%"
                marginRight="12%"
                alignItems="center"
                justifyContent="space-between"
              >
                {footerItems.map((footerItem) => (
                  <Box
                    key={footerItem.id}
                    fontSize="25px"
                    fontWeight={600}
                    color="primary.theme_lv1"
                    className={
                      footerIndex === footerItem.id
                        ? "icon_basic active"
                        : "icon_basic"
                    }
                    onClick={() => handleClick(footerItem.id)}
                  >
                    {footerItem.id === 0 && <IoHomeSharp />}
                    {footerItem.id === 1 && (
                      <GraphIcon
                        height="25px"
                        width="25px"
                        color="primary.theme_lv1"
                      />
                    )}
                    {footerItem.id === 2 && <IoMic />}
                    {footerItem.id === 3 && <IoSettingsSharp />}
                    {footerItem.id === 4 && <IoLogOut />}
                  </Box>
                ))}
              </Flex>
            </Center>
          </Box>
        </Box>
      </Box>
      {/* ログアウト確認モーダル */}
      <AlertDialog
        isCentered
        isOpen={isOpenDialog}
        leastDestructiveRef={dialogCancelRef}
        onClose={() => setIsOpenDialog(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent backgroundColor="common.base">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t("Home.logout")}
            </AlertDialogHeader>

            <AlertDialogBody
              my={12}
              fontSize="lg"
              textAlign="center"
              fontWeight="normal"
            >
              {t("Home.logoutConfirm")}
            </AlertDialogBody>

            <AlertDialogFooter justifyContent="space-between">
              <Button
                variant="ghost"
                ref={dialogCancelRef}
                onClick={() => setIsOpenDialog(false)}
              >
                {t("Modal.cancel")}
              </Button>
              <Button variant="ghost" onClick={() => window.location.reload()}>
                {t("Modal.yes")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}
