import {
  Ref,
  forwardRef,
  useCallback,
  useImperativeHandle,
  useState,
} from "react";
import { SoundVisualizerRef } from "../../types";
import { useAtom } from "jotai";
import {
  detectVolumeAtom,
  detectedVolumeByPhraseVisualizerAtom,
} from "../../store";
import { Box, useTheme } from "@chakra-ui/react";
import { mimosysVolumeToVolume, volumeToDecibel } from "../../utils/calcVolume";

type Props = {
  maxValue: number;
  underThreshold: number;
  upperThreshold: number;
  height?: string;
  width?: string;
  maxWidth?: string;
  showThreshold?: boolean;
  isRecording?: boolean;
};

export const BarSoundVisualizerV2 = forwardRef(
  (props: Props, ref: Ref<SoundVisualizerRef | null>) => {
    const showThreshold = props.showThreshold ?? true;
    const theme = useTheme();
    const [volume, setVolume] = useState(0);
    const [upperLimit] = useState(volumeToDecibel(props.upperThreshold));
    const [underLimit] = useState(volumeToDecibel(props.underThreshold));

    // ボリュームスレッショルド閾値
    const [detectVolumeForMimosys] = useAtom(detectVolumeAtom);
    const [detectVolume] = useState(
      mimosysVolumeToVolume(detectVolumeForMimosys)
    );
    const [logDetectVolume] = useState(volumeToDecibel(detectVolume));
    const [isDetectedVolumeAtom, setIsDetectedVolume] = useAtom(
      detectedVolumeByPhraseVisualizerAtom
    );

    function determineColor(volume: number): string {
      if (volume >= upperLimit) return theme.colors.primary["accent"];
      if (volume >= logDetectVolume && showThreshold)
        return theme.colors.primary["theme_lv1"];
      return theme.colors.primary["theme_lv2"];
    }

    const updateVolume = useCallback(
      (rawData: number, isTooLoud: boolean, isDetectedVolume: boolean) => {
        let rawLevel = isTooLoud ? 100 : volumeToDecibel(rawData);
        if (rawLevel > upperLimit) {
          rawLevel = upperLimit;
        }
        // 音量が閾値を超えていても、解析時の発話検知基準を足していない場合は閾値-1
        if (!isDetectedVolume && rawLevel >= logDetectVolume) {
          rawLevel = logDetectVolume - 1;
        }
        setVolume(rawLevel);
        // 一度でも発話検知基準を満たしていればOK
        if (!isDetectedVolumeAtom) {
          setIsDetectedVolume(isDetectedVolume);
        }
      },
      [upperLimit, logDetectVolume, isDetectedVolumeAtom, setIsDetectedVolume]
    );

    useImperativeHandle(ref, () => ({
      draw: (
        rawData: number,
        isTooLoud: boolean,
        isDetectedVolume: boolean
      ) => {
        updateVolume(rawData, isTooLoud, isDetectedVolume);
      },
    }));

    function convertVolumeToPercentage(volume: number): number {
      // 録音中である事を示す為にバーを小さく動かす
      if (props.isRecording && volume < underLimit) {
        // eslint-disable-next-line sonarjs/pseudo-random
        return Math.floor(Math.random() * 2);
      }
      if (volume > upperLimit) return 100;
      return ((volume - underLimit) / (upperLimit - underLimit)) * 100;
    }

    return (
      <Box
        height={props.height}
        width={props.width}
        maxWidth={props.maxWidth}
        position="relative"
        bg={theme.colors.primary["theme_lv3"]}
        margin="auto"
        display="block"
      >
        {/* underThresholdの位置に表示する線 */}
        {showThreshold && (
          <Box
            width="4px"
            height="100%"
            position="absolute"
            left={`${
              convertVolumeToPercentage(logDetectVolume) > 0
                ? convertVolumeToPercentage(logDetectVolume)
                : 0
            }%`}
            bgColor={theme.colors.primary["accent"]}
            zIndex="1"
          />
        )}
        {/* 音量バー */}
        <Box
          width={`${convertVolumeToPercentage(volume)}%`}
          height="100%"
          position="absolute"
          left="0"
          bgColor={determineColor(volume)}
          transition="width 0.2s"
        />
      </Box>
    );
  }
);
BarSoundVisualizerV2.displayName = "BarSoundVisualizer";
