import { ReactElement, useEffect, useRef } from "react";
import "chart.js/auto";
import Chart from "chart.js/auto";
import { ChartOptions } from "chart.js";
import { Box, useTheme } from "@chakra-ui/react";
import Annotation from "chartjs-plugin-annotation";

Chart.register(Annotation);

type AnnotationObject = {
  type: "box";
  xMin: number;
  xMax: number;
  backgroundColor: string;
  borderWidth: number;
};

// グレーアウトの範囲を決定するためのヘルパー関数
function createGreyOutAnnotations(
  list: number[][],
  totalLength: number
): AnnotationObject[] {
  const annotations: AnnotationObject[] = [];
  let start = 0;

  list.forEach((segment) => {
    // セグメントの前の範囲
    if (segment[0] > start) {
      annotations.push({
        type: "box",
        xMin: start,
        xMax: segment[0] - 1,
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        borderWidth: 0,
      });
    }
    start = segment[1] + 1;
  });

  // 最後のセグメントの後ろの範囲
  if (start < totalLength) {
    annotations.push({
      type: "box",
      xMin: start,
      xMax: totalLength - 1,
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      borderWidth: 0,
    });
  }

  return annotations;
}

type AudioVolumeGraphProps = {
  maxValue: number;
  valueList: number[];
  thresholdValue: number;
  voiceDetectionSegmentList?: number[][];
  currentPlayBackPosition?: number;
  isRecording?: boolean;
};

export function AudioVolumeGraph({
  maxValue,
  valueList,
  thresholdValue,
  voiceDetectionSegmentList,
  currentPlayBackPosition,
  isRecording,
}: Readonly<AudioVolumeGraphProps>): ReactElement {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);
  const theme = useTheme();
  const thresholdLineColor = theme.colors.primary["accent"];

  useEffect(() => {
    const freeChart = (): void => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };

    freeChart();
    if (!canvasRef.current) {
      return () => {
        freeChart();
      };
    }

    const ctx = canvasRef.current.getContext("2d");

    const charColorBelowThreshold = theme.colors.primary["theme_lv2"];
    const charColorAboveThreshold = theme.colors.primary["theme_lv1"];
    const belowThresholdListUpperSide = valueList.map((value) =>
      value <= thresholdValue ? value : null
    );
    const aboveThresholdListUpperSide = valueList.map((value) =>
      value > thresholdValue ? value : null
    );

    const data = {
      labels: valueList.map((_, index) => index),
      datasets: [
        {
          data: belowThresholdListUpperSide,
          fill: false,
          borderColor: charColorBelowThreshold,
          backgroundColor: charColorBelowThreshold,
          borderWidth: 1,
          stack: "stack0",
          barThickness: 1,
        },
        {
          data: aboveThresholdListUpperSide,
          fill: false,
          borderColor: charColorAboveThreshold,
          backgroundColor: charColorAboveThreshold,
          borderWidth: 1,
          stack: "stack0",
          barThickness: 2,
        },
      ],
    };

    if (isRecording) {
      const belowThresholdListLowerSide = valueList.map((value) =>
        value <= thresholdValue ? -value : null
      );
      const aboveThresholdListLowerSide = valueList.map((value) =>
        value > thresholdValue ? -value : null
      );
      data.datasets.push(
        {
          data: belowThresholdListLowerSide,
          fill: false,
          borderColor: charColorBelowThreshold,
          backgroundColor: charColorBelowThreshold,
          borderWidth: 1,
          stack: "stack0",
          barThickness: 1,
        },
        {
          data: aboveThresholdListLowerSide,
          fill: false,
          borderColor: charColorAboveThreshold,
          backgroundColor: charColorAboveThreshold,
          borderWidth: 1,
          stack: "stack0",
          barThickness: 2,
        }
      );
    }

    const greyOutAnnotationObjects = voiceDetectionSegmentList
      ? createGreyOutAnnotations(voiceDetectionSegmentList, valueList.length)
      : [];

    type BoxAnnotationType = {
      [key: string]: AnnotationObject;
    };

    const greyOutAnnotations =
      greyOutAnnotationObjects.reduce<BoxAnnotationType>(
        (acc, annotation, index) => {
          acc[`greyOut-${index}`] = annotation;
          return acc;
        },
        {}
      );

    // オプションの設定
    const options: ChartOptions<"bar"> = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        annotation: {
          annotations: {
            thresholdLinePlus: {
              type: "line",
              yMin: thresholdValue,
              yMax: thresholdValue,
              borderColor: isRecording ? thresholdLineColor : "red",
              borderWidth: 1,
              borderDash: isRecording ? [7, 5] : [],
              label: {
                content: "Threshold",
                position: "start",
              },
            },
            thresholdLineMinus: {
              type: "line",
              yMin: -thresholdValue,
              yMax: -thresholdValue,
              borderColor: isRecording ? thresholdLineColor : "red",
              borderWidth: 1,
              borderDash: isRecording ? [7, 5] : [],
              label: {
                content: "Threshold",
                position: "start",
              },
            },
            zeroLine: {
              type: "line",
              yMin: 0,
              yMax: 0,
              borderColor: "gray",
              borderWidth: isRecording ? 0.5 : 1,
              label: {
                content: "Zero",
                position: "start",
              },
            },
            // 再生位置のアノテーション
            playbackPosition: {
              type: "line",
              xMin: currentPlayBackPosition,
              xMax: currentPlayBackPosition,
              borderColor: "blue",
              borderWidth: currentPlayBackPosition ? 1 : 0,
            },
            ...greyOutAnnotations,
          },
        },
      },

      scales: {
        y: {
          display: isRecording ? false : true,
          stacked: true,
          ticks: {
            display: false,
          },
          max: maxValue,
          min: isRecording ? -maxValue : 0,
        },
        x: {
          display: isRecording ? false : true,
          stacked: true,
          ticks: {
            display: false,
          },
          grid: {
            drawOnChartArea: true,
            color: (context) => {
              if (context.tick && context.tick.value !== undefined) {
                return context.tick.value % 100 === 0
                  ? "rgba(0, 0, 0, 0.2)"
                  : "transparent";
              }
              return "transparent";
            },
          },
        },
      },
      animation: {
        duration: 0,
      },
    };

    if (ctx) {
      chartRef.current = new Chart(ctx, {
        type: "bar",
        data: data,
        options: options,
      });
    }

    return () => {
      freeChart();
    };
  }, [
    valueList,
    thresholdValue,
    voiceDetectionSegmentList,
    theme,
    currentPlayBackPosition,
    maxValue,
    isRecording,
    thresholdLineColor,
  ]);

  return (
    <Box
      bg={theme.colors.common["base"]}
      minW="100%"
      h={isRecording ? "100%" : "250px"}
    >
      <canvas ref={canvasRef} height={isRecording ? "100%" : "250px"} />
    </Box>
  );
}
