import { Box, Icon } from "@chakra-ui/react";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { FaMicrophone } from "react-icons/fa";
import {
  CALIBRATION_MAX_LOG_POWER,
  CALIBRATION_MIN_LOG_POWER,
} from "../../constants";

interface VerticalControlBarProps {
  initialValue: number;
  isActive?: boolean;
  onHeightChange: (heightPercent: number) => void;
}

function calculateValueFromPercent(percent: number): number {
  return (
    CALIBRATION_MIN_LOG_POWER +
    (CALIBRATION_MAX_LOG_POWER - CALIBRATION_MIN_LOG_POWER) * percent
  );
}

function calculatePercentFromValue(value: number): number {
  return (
    (value - CALIBRATION_MIN_LOG_POWER) /
    (CALIBRATION_MAX_LOG_POWER - CALIBRATION_MIN_LOG_POWER)
  );
}

export function VerticalControlBar({
  initialValue,
  isActive = true,
  onHeightChange,
}: Readonly<VerticalControlBarProps>): ReactElement {
  const [height, setHeight] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const barRef = useRef<HTMLDivElement>(null);

  const calculateInitialHeight = useCallback(() => {
    if (!barRef.current) return;
    const rect = barRef.current.getBoundingClientRect();
    const initialPercent = calculatePercentFromValue(initialValue);
    const initialHeight = initialPercent * rect.height;
    setHeight(initialHeight);
  }, [initialValue]);

  useEffect(() => {
    calculateInitialHeight();
    window.addEventListener("resize", calculateInitialHeight);
    return () => {
      window.removeEventListener("resize", calculateInitialHeight);
    };
  }, [calculateInitialHeight]);

  const updateHeight = useCallback(
    (clientY: number) => {
      if (!barRef.current) return;
      const rect = barRef.current.getBoundingClientRect();
      const newHeight = Math.max(
        0,
        Math.min(rect.bottom - clientY, rect.height)
      );
      setHeight(newHeight);
      const percent = newHeight / rect.height;
      const value = calculateValueFromPercent(percent);
      onHeightChange(value);
    },
    [onHeightChange]
  );

  const handleStart = useCallback(
    (clientY: number) => {
      if (!isActive) return;
      setIsDragging(true);
      updateHeight(clientY);
    },
    [isActive, updateHeight]
  );

  const handleMove = useCallback(
    (clientY: number) => {
      if (isDragging) {
        updateHeight(clientY);
      }
    },
    [isDragging, updateHeight]
  );

  const handleEnd = useCallback(() => {
    setIsDragging(false);
  }, []);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      handleStart(e.clientY);
    },
    [handleStart]
  );

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      handleMove(e.clientY);
    },
    [handleMove]
  );

  const handleMouseUp = useCallback(() => {
    handleEnd();
  }, [handleEnd]);

  const handleTouchStart = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      if (e.target === barRef.current) {
        e.preventDefault();
      }
      handleStart(e.touches[0].clientY);
    },
    [handleStart]
  );

  const handleTouchMove = useCallback(
    (e: TouchEvent) => {
      if (isDragging) {
        e.preventDefault();
        handleMove(e.touches[0].clientY);
      }
    },
    [isDragging, handleMove]
  );

  const handleTouchEnd = useCallback(() => {
    handleEnd();
  }, [handleEnd]);

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    document.addEventListener("touchmove", handleTouchMove, { passive: false });
    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.removeEventListener("touchmove", handleTouchMove);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, [handleMouseMove, handleMouseUp, handleTouchMove, handleTouchEnd]);

  return (
    <Box
      ref={barRef}
      onMouseDown={handleMouseDown}
      onTouchStart={handleTouchStart}
      w="60px"
      h="180px"
      bg={isActive ? "primary.theme_lv3" : "gray.100"}
      position="relative"
      cursor={isActive ? "pointer" : "not-allowed"}
      rounded="xl"
      overflow="hidden"
    >
      <Box
        position="absolute"
        bottom="0"
        w="100%"
        h={`${height}px`}
        bg={isActive ? "primary.theme_lv1" : "gray.300"}
      />
      <Box
        position="absolute"
        top="75%"
        left="50%"
        transform="translate(-50%, -50%)"
      >
        <Icon as={FaMicrophone} w={6} h={6} color="white" />
      </Box>
    </Box>
  );
}
