import { ReactElement, useEffect, useRef } from "react";
import "chart.js/auto";
import Chart from "chart.js/auto";
import { ChartOptions } from "chart.js";
import { Box, Flex, Text } from "@chakra-ui/react";
import Annotation from "chartjs-plugin-annotation";

Chart.register(Annotation);

type AnnotationObject = {
  type: "box";
  xMin: number;
  xMax: number;
  backgroundColor: string;
  borderWidth: number;
};

// グレーアウトの範囲を決定するためのヘルパー関数
function createGreyOutAnnotations(
  list: number[][],
  totalLength: number
): AnnotationObject[] {
  const annotations: AnnotationObject[] = [];
  let start = 0;

  list.forEach((segment) => {
    // セグメントの前の範囲
    if (segment[0] > start) {
      annotations.push({
        type: "box",
        xMin: start,
        xMax: segment[0] - 1,
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        borderWidth: 0,
      });
    }
    start = segment[1] + 1;
  });

  // 最後のセグメントの後ろの範囲
  if (start < totalLength) {
    annotations.push({
      type: "box",
      xMin: start,
      xMax: totalLength - 1,
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      borderWidth: 0,
    });
  }

  return annotations;
}

type AudioVolumeGraphForDevelopProps = {
  maxVolumeListWithVadResult: { value: number; class: number }[];
  voiceDetectionSegment: number[][];
};

export function AudioVolumeGraphForDevelop({
  maxVolumeListWithVadResult,
  voiceDetectionSegment,
}: Readonly<AudioVolumeGraphForDevelopProps>): ReactElement {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const chartRef = useRef<Chart | null>(null);

  console.log("voiceDetectionSegment", voiceDetectionSegment);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.destroy();
    }

    if (canvasRef.current) {
      const maxValue = maxVolumeListWithVadResult.reduce((max, item) => {
        return item.value > max ? item.value : max;
      }, 0);

      const ctx = canvasRef.current.getContext("2d");

      const detectedList = maxVolumeListWithVadResult.map((item) =>
        item.class === 1 ? item.value : null
      );
      const nondetectedList = maxVolumeListWithVadResult.map((item) =>
        item.class === 0 ? item.value : null
      );

      const data = {
        labels: maxVolumeListWithVadResult.map((_, index) => index),
        datasets: [
          {
            data: detectedList,
            fill: false,
            borderColor: "green",
            backgroundColor: "green",
            borderWidth: 1,
            stack: "stack0",
            barThickness: 1,
          },
          {
            data: nondetectedList,
            fill: false,
            borderColor: "lightgray",
            backgroundColor: "lightgray",
            borderWidth: 1,
            stack: "stack0",
            barThickness: 2,
          },
        ],
      };

      const greyOutAnnotationObjects = createGreyOutAnnotations(
        voiceDetectionSegment,
        maxVolumeListWithVadResult.length
      );

      type BoxAnnotationType = {
        [key: string]: AnnotationObject;
      };

      const greyOutAnnotations =
        greyOutAnnotationObjects.reduce<BoxAnnotationType>(
          (acc, annotation, index) => {
            acc[`greyOut-${index}`] = annotation;
            return acc;
          },
          {}
        );

      // オプションの設定
      const options: ChartOptions<"bar"> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          annotation: {
            annotations: {
              zeroLine: {
                type: "line",
                yMin: 0,
                yMax: 0,
                borderColor: "gray",
                borderWidth: 1,
                label: {
                  content: "Zero",
                  position: "start",
                },
              },
              ...greyOutAnnotations,
            },
          },
        },

        scales: {
          y: {
            stacked: true,
            ticks: {
              display: false,
            },
            max: maxValue,
          },
          x: {
            stacked: true,
            ticks: {
              display: false,
            },
            grid: {
              drawOnChartArea: true,
              color: (context) => {
                if (context.tick && context.tick.value !== undefined) {
                  return context.tick.value % 100 === 0
                    ? "rgba(0, 0, 0, 0.2)"
                    : "transparent";
                }
                return "transparent";
              },
            },
          },
        },
        animation: {
          duration: 0,
        },
      };

      if (ctx) {
        chartRef.current = new Chart(ctx, {
          type: "bar",
          data: data,
          options: options,
        });
      }
    }

    return () => {
      if (chartRef.current) {
        chartRef.current.destroy();
      }
    };
  }, [maxVolumeListWithVadResult, voiceDetectionSegment]);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      direction="column"
      minW="100%"
    >
      <Box
        w="full"
        display="flex"
        justifyContent="center"
        flexDirection="column"
      >
        <Text textAlign="center">自動設定によるクラスタリング結果</Text>
        <Flex justifyContent="center" w="full" gap={4}>
          <Text fontSize="sm" color="green">
            ■：発話
          </Text>
          <Text fontSize="sm" color="gray">
            ■：非発話
          </Text>
          <Text fontSize="sm">| |：発話範囲</Text>
        </Flex>
        <Text fontSize="sm" textAlign="center">
          発話開始条件：初めて発話が5連続 / 発話終了条件：最後に発話が5連続
        </Text>
      </Box>
      <Box backgroundColor="white" minW="100%" h="250px">
        <canvas ref={canvasRef} height="250px" />
      </Box>
    </Flex>
  );
}
