import { ReactElement, useEffect, useRef, useState } from "react";
import { appAxios } from "../../../utils/appAxios";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Flex } from "@chakra-ui/react";
import { useAtom } from "jotai";
import {
  accessTokenInfoAtom,
  splashImageInfoAtom,
  isQueryModeAtom,
  recTypeAtom,
} from "../../../store";
import {
  MimosysDataAtom,
  MimosysDateType,
  latestEmotionAtom,
} from "../../../stores/mimosysStore";
import {
  ANALYSIS_ENGINE,
  APP_TYPE,
  SKIP_QUESTIONNAIRES,
} from "../../../environments";
import { getSelectableEngineList } from "../../../pages/engines";
import { homeSplashDisplayAtom } from "../ResultHome/HomeAtoms";
import { SplashImage } from "../SplashImage";
import {
  AnalysisResult as MimosysResultType,
  getEmotionAverage,
} from "../../../engines/mimosysEngine";
import { sentryLog } from "../../../../sentry";
import { useToastMessage } from "../../../hooks/useToastMessage";

/*
 * Sphash表示
 */
export function Splash(): ReactElement {
  // console.log("in Splash.");

  const [isExpired, setExpired] = useState(false);
  const [isApiCompleted, setApiCompleted] = useState(false);

  const splash = useRef(0);
  const navigate = useNavigate();
  const [splashImageInfo] = useAtom(splashImageInfoAtom);
  const [isQueryLogin] = useAtom(isQueryModeAtom);
  const [, setMimosysData] = useAtom(MimosysDataAtom);
  const [, setMimosysEmotions] = useAtom(latestEmotionAtom);
  const [, setRecType] = useAtom(recTypeAtom);
  const [, setHomeSplashDisplay] = useAtom(homeSplashDisplayAtom);
  const toastMessage = useToastMessage();

  const [accessTokenInfo] = useAtom(accessTokenInfoAtom);
  if (accessTokenInfo === null) throw new Error("no token");

  /*
   * 2秒タイムアウト計測
   */
  useEffect(() => {
    const id = setTimeout(() => {
      setExpired(true);
    }, 2 * 1000);
    return () => {
      clearTimeout(id);
    };
  });

  /*
   * Mi1解析結果リスト取得
   */
  useEffect(() => {
    setRecType(0);
    const fetchData = async (): Promise<void> => {
      if (!isQueryLogin) {
        // クエリログインではない場合
        // Mi-1解析結果一覧取得
        // console.log("/analysis/mi1/list start");
        if (splash.current === 0) {
          splash.current += 1;
          try {
            const analysisMi1List = await appAxios.get(
              "/analysis/mi1/list?detail=false"
            );
            // console.log("/analysis/mi1/list end");
            const inputData = Object.values(
              analysisMi1List.data
            ) as MimosysDateType[];
            setMimosysData(inputData);

            if (APP_TYPE === "poc") {
              const latestData = inputData[inputData.length - 1];
              const latestFileId = latestData.file_id;
              const latestMi1Response = await appAxios.get(
                `/analysis/mi1?file_id=${latestFileId}`
              );
              const latestMi1Result =
                latestMi1Response.data as MimosysResultType;
              const averageEmotions = getEmotionAverage(
                latestMi1Result.mimosys_sub_parameters.emotions
              );
              setMimosysEmotions(averageEmotions);
            }
          } catch (e) {
            sentryLog(e);
            toastMessage(e);
          } finally {
            setApiCompleted(true);
          }
        }
      } else {
        // クエリログインの場合
        setApiCompleted(true);
      }
    };
    fetchData();
  }, [
    setRecType,
    isQueryLogin,
    setMimosysData,
    setHomeSplashDisplay,
    setMimosysEmotions,
    toastMessage,
  ]);

  /*
   * 遷移
   */
  useEffect(() => {
    if (isExpired && isApiCompleted && isApiCompleted) {
      let startTo = "";
      if (!isQueryLogin) {
        // クエリログインでは無い場合、ホーム画面に遷移
        setHomeSplashDisplay(false);
        return;
      }
      if (ANALYSIS_ENGINE) {
        const page = SKIP_QUESTIONNAIRES ? "recording" : "questionnaires";
        startTo = `../engines/${ANALYSIS_ENGINE}/${page}`;
      } else {
        const url_list = getSelectableEngineList(accessTokenInfo.access_token);
        if (url_list.length == 1)
          startTo = "../engines/" + url_list[0] + "/questionnaires";
        else startTo = "../engines";
      }
      // クエリログインの場合、録音開始の画面に遷移
      // navigate(startTo, { replace: true });
      navigate(startTo);
    }
  }, [
    isExpired,
    isApiCompleted,
    isQueryLogin,
    navigate,
    accessTokenInfo.access_token,
    setHomeSplashDisplay,
  ]);

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      height="100vh"
      width="100vw"
    >
      <motion.div
        style={{ opacity: 0, width: "100%", height: "100%" }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <SplashImage imageInfo={splashImageInfo} />
      </motion.div>
    </Flex>
  );
}
