import { ReactElement, useCallback, useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@chakra-ui/react";
import { RecordedData } from "../../types";

const POINT_RADIUS = 4;
const POINT_AND_SQUARE_LINE_WIDTH = 8;
const SQUARE_WIDTH = POINT_RADIUS * 2;

function clearCanvas(ctx: CanvasRenderingContext2D): void {
  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
}

function drawPoint(
  ctx: CanvasRenderingContext2D,
  strokeColor: string,
  fillColor: string,
  x: number,
  y: number
): void {
  ctx.beginPath();
  ctx.lineWidth = POINT_AND_SQUARE_LINE_WIDTH;
  ctx.strokeStyle = strokeColor;
  ctx.fillStyle = fillColor;
  ctx.arc(x, y, POINT_RADIUS * 2, 0, Math.PI * 2);
  ctx.stroke();
  ctx.fill();
}

function drawSquare(
  ctx: CanvasRenderingContext2D,
  strokeColor: string,
  fillColor: string,
  x: number,
  y: number
): void {
  ctx.beginPath();
  ctx.lineWidth = POINT_AND_SQUARE_LINE_WIDTH;
  ctx.strokeStyle = strokeColor;
  ctx.fillStyle = fillColor;
  ctx.rect(x, y, SQUARE_WIDTH * 2, SQUARE_WIDTH * 2);
  ctx.stroke();
  ctx.fill();
}

export function RecordingStepper(props: Readonly<{
  progress: number;
  maxProgress: number;
  allRecordedData: RecordedData[];
}>): ReactElement {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [[width, height], setComponentSize] = useState([0, 0]);
  const getContext = (): CanvasRenderingContext2D | null => {
    return canvasRef.current?.getContext("2d") || null;
  };
  const theme = useTheme();
  const drawBar = useCallback(() => {
    const ctx = getContext();
    if (!ctx) return;

    const canvasWidth = ctx.canvas.width;
    const canvasHeight = ctx.canvas.height;

    const color = theme.colors.primary["theme_lv1"];

    const centerY = canvasHeight / 2;
    const pointSpan = canvasWidth / props.maxProgress;
    const shortSpan = pointSpan / 2;

    clearCanvas(ctx);

    // 全体のライン
    ctx.beginPath();
    ctx.moveTo(5, centerY);
    ctx.strokeStyle = `${color}66`;
    ctx.lineWidth = 4;
    ctx.lineCap = "round";
    ctx.lineTo(canvasWidth, centerY);
    ctx.stroke();

    // 録音済みの位置までのライン
    const recordedWidth = shortSpan + props.progress * pointSpan;
    ctx.beginPath();
    ctx.moveTo(5, centerY);
    ctx.strokeStyle = color;
    ctx.lineWidth = 6;
    ctx.lineCap = "round";
    ctx.lineTo(recordedWidth, centerY);
    ctx.stroke();

    for (let circlePoint = 0; circlePoint <= props.maxProgress; circlePoint++) {
      const x =
        circlePoint === props.maxProgress
          ? canvasWidth - (SQUARE_WIDTH + POINT_AND_SQUARE_LINE_WIDTH / 2)
          : shortSpan + circlePoint * pointSpan;
      if (circlePoint === props.maxProgress) {
        drawSquare(
          ctx,
          color,
          "white",
          x - SQUARE_WIDTH,
          (canvasHeight - SQUARE_WIDTH) / 2.5
        );
      } else if (circlePoint === props.progress) {
        drawPoint(ctx, color, "white", x, centerY);
      } else if (
        circlePoint < props.allRecordedData.length &&
        props.allRecordedData[circlePoint].isSkipped === true
      ) {
        drawPoint(ctx, color, "white", x, centerY);
      } else if (
        circlePoint < props.allRecordedData.length &&
        props.allRecordedData[circlePoint].isSkipped === false
      ) {
        drawPoint(ctx, color, color, x, centerY);
      } else {
        drawPoint(ctx, `${color}66`, "white", x, centerY);
      }
    }
  }, [
    theme.colors.primary,
    props.maxProgress,
    props.progress,
    props.allRecordedData,
  ]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setComponentSize([
        entries[0].contentRect.width,
        entries[0].contentRect.height,
      ]);
    });
    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    drawBar();
  }, [width, height, drawBar]);

  return (
    <Box ref={containerRef} width="full" height={5}>
      <div style={{ height, width }}>
        <canvas
          ref={canvasRef}
          height={height * 2}
          width={width * 2}
          style={{ width, height }}
        />
      </div>
    </Box>
  );
}
