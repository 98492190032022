import { ReactElement, useEffect, useState } from "react";
import { ReactSVG } from "react-svg";
import { setSVGFillColor } from "../../utils/svgHelper";
import { Image, useTheme } from "@chakra-ui/react";
import { SplashImageInfoType } from "../../store";

export function SplashImage({
  imageInfo,
}: Readonly<{
  imageInfo: SplashImageInfoType;
}>): ReactElement {
  const theme = useTheme();
  const [imagePath, setImagePath] = useState("");
  useEffect(() => {
    setImagePath(imageInfo.splash_path);
  }, [imageInfo]);

  // splash_noが0なら透明化、1ならテーマカラー反映
  const handleBeforeInjection = (svg: SVGSVGElement): void => {
    svg.setAttribute("width", "100%");
    svg.setAttribute("height", "100%");
    svg.style.objectFit = "contain";
    svg.style.position = "absolute";
    svg.style.top = "0";
    svg.style.left = "0";
    svg.style.bottom = "0";
    svg.style.right = "0";
    svg.style.margin = "auto";
    svg.style.flexShrink = "0";
    if (imageInfo.splash_no === 0) {
      setSVGFillColor(svg, "path", "transparent");
    } else if (imageInfo.splash_no === 1) {
      setSVGFillColor(svg, ".cls-1", theme.colors.primary["theme_lv1"]);
    }
  };

  return (
    <>
      {imageInfo.splash_no === 0 || imageInfo.splash_no === 1 ? (
        <ReactSVG src={imagePath} beforeInjection={handleBeforeInjection} />
      ) : (
        <Image
          src={imagePath}
          alt=""
          objectFit="contain"
          objectPosition="center"
          width="100%"
          height="100%"
        />
      )}
    </>
  );
}
