import { ReactElement, ReactNode } from "react";
import { Box, useTheme } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useAtom } from "jotai";
import { windowSizeAtom } from "../../../hooks/useWindowSize";
import {
  CartesianGrid,
  ComposedChart,
  Label,
  Line,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { monthDataAtom, weekDataAtom, yearDataAtom } from "./ResultAtom";
import {
  mentalActivitySdSetAtom,
  scoreAverageAtom,
  vitalitySdSetAtom,
  mi1NormalRange,
} from "../ResultHome/HomeAtoms";
import { CustomTooltip } from "../ResultHome/HomerChart";
import { ResultChartData } from "../../../stores/mimosysStore";
import {
  CustomXAxisTick,
  getTicks,
  CustomDot,
  averageLabelPosition,
} from "../ResultCommon/ChartCustom";
import {
  outputStenScoreAtom,
  hasMi1ResultAtom,
  hasMentalActivityAtom,
  hasVitalityAtom,
} from "../../../store";
import { useModifiedTranslation } from "../../../hooks/useModifiedTranslation";

type ChartDataType = {
  average: number;
  sdTop: number;
  sdBottom: number;
  show: boolean;
};

/**
 * グラフ表示【左右ナビゲーション・トグル付き】
 * グラフ表示 / グラフ内でマーカーにマウスオーバー時のツールチップ表示
 * 結果画面のグラフコンポーネント
 * @param props
 * @constructor
 */
function ChartBody(
  props: Readonly<{
    data: ResultChartData[];
    children: ReactNode;
    vitality: ChartDataType;
    mentalActivity: ChartDataType;
    mi1: ChartDataType;
  }>
): ReactElement {
  dayjs.locale("ja");
  const [windowSize] = useAtom(windowSizeAtom);

  let chartWidth = 0;
  let chartHeight = 0;

  // レスポンス表示調整
  if (windowSize.width >= 800) {
    chartWidth = 660;
    chartHeight = 440;
  } else if (
    (windowSize.width > 660 && windowSize.width < 800) ||
    windowSize.width <= 660
  ) {
    chartWidth = Math.round(windowSize.width) * 0.8;
    chartHeight = Math.round(chartWidth * 0.7);
  }

  // console.log("chartHeight:" + chartHeight);

  const [hasMi1Result] = useAtom(hasMi1ResultAtom);
  const [hasMentalActivity] = useAtom(hasMentalActivityAtom);
  const [hasVitality] = useAtom(hasVitalityAtom);
  const [outputStenScore] = useAtom(outputStenScoreAtom);
  const mi1Show = outputStenScore ? props.mi1.show : false;

  /*
  console.log("hasMi1Result:" + JSON.stringify(hasMi1Result,null,'\t'));
  console.log("hasMentalActivity:" + JSON.stringify(hasMentalActivity,null,'\t'));
  console.log("hasVitality:" + JSON.stringify(hasVitality,null,'\t'));
  console.log("mi1Show:" + JSON.stringify(mi1Show,null,'\t'));
  */

  const y1Domain =
    mi1Show && !props.mentalActivity.show && !props.vitality.show
      ? [1, 10]
      : [1, 100];

  // 解析結果グラフのY軸左側の間隔設定
  const y1Ticks =
    mi1Show && !props.mentalActivity.show && !props.vitality.show
      ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
      : [20, 40, 60, 80, 100];

  const t = useModifiedTranslation();
  const theme = useTheme();
  const mi1ResultColor = theme.colors.mi1Result["primary"];
  const mentalActivityColor = theme.colors.mentalActivity["primary"];
  const mentalActivitySdAreaColor = theme.colors.mentalActivity["sdArea"];
  const vitalityColor = theme.colors.vitality["primary"];
  const vitalitySdAreaColor = theme.colors.vitality["sdArea"];
  const gradientBottom = theme.colors.common["base"];
  const tickColor = theme.colors.common_text["gray"];
  const averageLineColor = theme.colors.graph["referenceLine"];

  return (
    <Box maxWidth="960px" backgroundColor="common.base">
      <ResponsiveContainer height={chartHeight} width={chartWidth}>
        <ComposedChart
          data={props.data}
          margin={{ top: 28, right: 8, left: 8, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorMA" x1="0" y1="0" x2="0" y2="1">
              <stop
                offset="5%"
                stopColor={mentalActivityColor}
                stopOpacity={0.1}
              />
              <stop offset="95%" stopColor={gradientBottom} stopOpacity={0.1} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="colorVA" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor={vitalityColor} stopOpacity={0.1} />
              <stop offset="95%" stopColor={gradientBottom} stopOpacity={0.1} />
            </linearGradient>
          </defs>

          <CartesianGrid vertical={false} />
          {mi1Show && !props.mentalActivity.show && !props.vitality.show ? (
            <YAxis /* 条件1:こころ指数Onのみの左Y軸  */
              orientation="left"
              tick={{
                fontSize: 15,
                fill: tickColor,
                fontFamily: "Oswald",
                fontWeight: 300,
              }}
              tickLine={false}
              width={20}
              axisLine={false}
              domain={[0, 10]}
              ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
            />
          ) : (
            <YAxis /* 条件1以外の左Y軸  */
              yAxisId={1}
              tick={{
                fontSize: 15,
                fill: tickColor,
                fontFamily: "Oswald",
                fontWeight: 300,
              }}
              domain={y1Domain}
              ticks={y1Ticks}
              tickLine={false}
              width={20}
              axisLine={false}
            />
          )}

          {mi1Show && (
            <YAxis /* 条件2:こころ指数OnのY軸基本設定  */
              orientation="right"
              tick={{
                fontSize: 15,
                fill: tickColor,
                fontFamily: "Oswald",
                fontWeight: 300,
              }}
              tickLine={false}
              width={20}
              axisLine={false}
              domain={[0, 10]}
              // 解析結果グラフのY軸右側の間隔設定
              // ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
              ticks={[2, 4, 6, 8, 10]}
            />
          )}
          {props.children}
          <Tooltip content={<CustomTooltip />} />

          {!hasMi1Result && !hasMentalActivity && !hasVitality && (
            <ReferenceArea /* データがない場合 */
              yAxisId={1}
              y1={100}
              y2={0}
              fill="common.base"
              label={{
                value: "NO DATA",
                fontFamily: "Oswald",
                stroke: "common_text.gray",
                fill: "#808080",
                fontSize: "26px",
                position: "center",
              }}
            />
          )}

          {!props.mentalActivity.show &&
            !mi1Show &&
            props.vitality.show &&
            props.vitality.average !== 0 && (
              <ReferenceLine
                yAxisId={1}
                y={props.vitality.average * 100}
                strokeWidth={0.5}
                stroke={averageLineColor}
              >
                {/* 元気圧平均 */}
                <Label
                  fontSize={7}
                  fill={averageLineColor}
                  value={t("Graph.common.averageVitality")}
                  position={averageLabelPosition(
                    props.data,
                    props.vitality.average * 100,
                    "vitality"
                  )}
                />
              </ReferenceLine>
            )}

          {!props.mentalActivity.show &&
            !mi1Show &&
            props.vitality.show &&
            props.vitality.sdTop !== 0 && (
              <ReferenceArea
                yAxisId={1}
                y1={props.vitality.sdTop}
                y2={props.vitality.sdBottom}
                fill={vitalitySdAreaColor}
                strokeOpacity={0}
              />
            )}

          {!props.vitality.show &&
            !mi1Show &&
            props.mentalActivity.show &&
            props.mentalActivity.average !== 0 && (
              <ReferenceLine
                yAxisId={1}
                y={props.mentalActivity.average * 100}
                strokeWidth={0.5}
                stroke={averageLineColor}
              >
                {/* 心の活量値平均 */}
                <Label
                  fontSize={7}
                  fill={averageLineColor}
                  value={t("Graph.common.averageMentalActivity")}
                  position={averageLabelPosition(
                    props.data,
                    props.mentalActivity.average * 100,
                    "mental_activity"
                  )}
                />
              </ReferenceLine>
            )}
          {!props.vitality.show &&
            !mi1Show &&
            props.mentalActivity.show &&
            props.mentalActivity.sdTop !== 0 && (
              <ReferenceArea
                yAxisId={1}
                y1={props.mentalActivity.sdTop}
                y2={props.mentalActivity.sdBottom}
                fill={mentalActivitySdAreaColor}
                strokeOpacity={0}
              />
            )}

          {/* 心の活量値 */}
          {props.mentalActivity.show && (
            <Line
              yAxisId={1}
              connectNulls
              name={t("Graph.common.mentalActivity")}
              strokeLinecap="round"
              isAnimationActive={false}
              dot={
                <CustomDot
                  dotColor={mentalActivityColor}
                  strokeWidth="2"
                  thresholdAlertValue={props.mentalActivity.sdBottom}
                />
              }
              dataKey="mental_activity100"
              strokeWidth={1}
              fillOpacity={1}
              stroke={mentalActivityColor}
            />
          )}

          {/* 元気圧 */}
          {props.vitality.show && (
            <Line
              yAxisId={1}
              connectNulls
              name={t("Graph.common.vitality")}
              strokeLinecap="round"
              isAnimationActive={false}
              dot={{
                stroke: vitalityColor,
                fill: vitalityColor,
                strokeWidth: 2,
              }}
              dataKey="vitality100"
              strokeWidth={1}
              stroke={vitalityColor}
              fillOpacity={1}
            />
          )}

          {/* こころ指数 */}
          {mi1Show && hasMi1Result && (
            <Line
              connectNulls
              name={t("Graph.common.mi1Result")}
              strokeLinecap="round"
              isAnimationActive={false}
              dot={
                <CustomDot
                  dotColor={mi1ResultColor}
                  strokeWidth="2"
                  thresholdAlertValue={props.mi1.sdBottom}
                />
              }
              dataKey="mi1_result"
              strokeWidth={1}
              stroke={mi1ResultColor}
              fillOpacity={1}
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}

export function WeekChart(
  props: Readonly<{
    showMentalActivity: boolean;
    showVitality: boolean;
    showMi1: boolean;
  }>
): ReactElement {
  const [mimosys] = useAtom(weekDataAtom);
  const [[vitalityAve, mentalActivityAve, mi1Ave]] = useAtom(scoreAverageAtom);
  const [[vitalitySdTop, vitalitySdBottom]] = useAtom(vitalitySdSetAtom);
  const [[mentalActivitySdTop, mentalActivitySdBottom]] = useAtom(
    mentalActivitySdSetAtom
  );
  const [mi1NormalRangeTop, mi1NormalRangeBottom] = mi1NormalRange;
  return (
    <ChartBody
      data={mimosys}
      vitality={{
        average: vitalityAve,
        sdTop: vitalitySdTop,
        sdBottom: vitalitySdBottom,
        show: props.showVitality,
      }}
      mentalActivity={{
        average: mentalActivityAve,
        sdTop: mentalActivitySdTop,
        sdBottom: mentalActivitySdBottom,
        show: props.showMentalActivity,
      }}
      mi1={{
        average: mi1Ave,
        sdTop: mi1NormalRangeTop,
        sdBottom: mi1NormalRangeBottom,
        show: props.showMi1,
      }}
    >
      <XAxis
        dataKey="date"
        domain={["dataMin", "dataMax"]}
        interval={0}
        tickFormatter={(props) => dayjs(props).format("D")}
        type="number"
        tick={<CustomXAxisTick />}
        ticks={getTicks(
          dayjs(mimosys[mimosys.length - 1].date)
            .startOf("day")
            .unix() * 1000,
          14
        )}
        tickLine={false}
      />
    </ChartBody>
  );
}

export function MonthChart(
  props: Readonly<{
    showMentalActivity: boolean;
    showVitality: boolean;
    showMi1: boolean;
  }>
): ReactElement {
  const [mimosys] = useAtom(monthDataAtom);
  const [[vitalityAve, mentalActivityAve, mi1Ave]] = useAtom(scoreAverageAtom);
  const [[vitalitySdTop, vitalitySdBottom]] = useAtom(vitalitySdSetAtom);
  const [[mentalActivitySdTop, mentalActivitySdBottom]] = useAtom(
    mentalActivitySdSetAtom
  );
  const [mi1NormalRangeTop, mi1NormalRangeBottom] = mi1NormalRange;
  const dayCount = dayjs(mimosys[mimosys.length - 1].date)
    .startOf("day")
    .diff(dayjs(mimosys[0].date).startOf("day"), "day");

  return (
    <ChartBody
      data={mimosys}
      vitality={{
        average: vitalityAve,
        sdTop: vitalitySdTop,
        sdBottom: vitalitySdBottom,
        show: props.showVitality,
      }}
      mentalActivity={{
        average: mentalActivityAve,
        sdTop: mentalActivitySdTop,
        sdBottom: mentalActivitySdBottom,
        show: props.showMentalActivity,
      }}
      mi1={{
        average: mi1Ave,
        sdTop: mi1NormalRangeTop,
        sdBottom: mi1NormalRangeBottom,
        show: props.showMi1,
      }}
    >
      <XAxis
        dataKey="date"
        domain={["dataMin", "dataMax"]}
        interval={0}
        tickFormatter={(props) => dayjs(props).format("D")}
        type="number"
        tick={<CustomXAxisTick />}
        ticks={getTicks(
          dayjs(mimosys[mimosys.length - 1].date)
            .startOf("day")
            .unix() * 1000,
          dayCount
        )}
        tickLine={false}
      />
    </ChartBody>
  );
}

export function YearChart(
  props: Readonly<{
    showMentalActivity: boolean;
    showVitality: boolean;
    showMi1: boolean;
  }>
): ReactElement {
  const [mimosys] = useAtom(yearDataAtom);
  const [[vitalityAve, mentalActivityAve, mi1Ave]] = useAtom(scoreAverageAtom);
  const [[vitalitySdTop, vitalitySdBottom]] = useAtom(vitalitySdSetAtom);
  const [[mentalActivitySdTop, mentalActivitySdBottom]] = useAtom(
    mentalActivitySdSetAtom
  );
  const [mi1NormalRangeTop, mi1NormalRangeBottom] = mi1NormalRange;
  const theme = useTheme();
  const tickColor = theme.colors.text["main_text_lv1"];
  return (
    <ChartBody
      data={mimosys}
      vitality={{
        average: vitalityAve,
        sdTop: vitalitySdTop,
        sdBottom: vitalitySdBottom,
        show: props.showVitality,
      }}
      mentalActivity={{
        average: mentalActivityAve,
        sdTop: mentalActivitySdTop,
        sdBottom: mentalActivitySdBottom,
        show: props.showMentalActivity,
      }}
      mi1={{
        average: mi1Ave,
        sdTop: mi1NormalRangeTop,
        sdBottom: mi1NormalRangeBottom,
        show: props.showMi1,
      }}
    >
      <XAxis
        dataKey="date"
        interval={0}
        tickFormatter={(props) => dayjs(props).format("M")}
        tick={{
          fontSize: 12,
          fill: tickColor,
          fontFamily: "Oswald",
          fontWeight: 300,
        }}
        tickLine={false}
      />
    </ChartBody>
  );
}
